/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';

import { getBrandName } from '../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import { getIsBot } from '../../../state/ducks/App/App-Selectors';
import { getUserType } from '../../../state/ducks/App/ducks/Common/Common-Selectors';
import useGetAccessTokenSafely from '../../gql/hooks/useGetAccessTokenSafely';
import { GRAPHQL_ENV } from '../../gql';
import findPersonalizedContentSF from '../../gql/queries/findPersonalizedContentSF';
import useBrowserUUID from '../useBrowserUUID';

/**
 * @description - used to communicate with customer-personalize API for personalized content.
 * @param { string } campaignFeature - salesforce campaign name
 * @param {boolean} isSympathySku -
 * @param {string} partNumber -
 * @param {object} targeting -
 * @param { object } apolloOptions - overrides | additional options to pass to useQuery API
 * @returns { object } - { loading, error, data, variables } -> add variables.skip to loading handling and variables.eidError to error handling
 */

const useSalesforcePersonalizedContentQuery = ({
    interactionName,
    targeting,
    apolloOptions = {},
    queryName = 'PersonalizedContent',
    featureFlag = true, // default but negated to skip if statement
    isSessionHasAccount = false, // needed for checking if guest user has account
}) => {
    const isBot = useSelector(getIsBot);
    // if ff true negate make false and skip statement and make api call / if false negate and make true to return from call and continue
    if (isBot || !featureFlag) {
        return {
            loading: false, error: false, data: null, variables: { skip: false },
        };
    }

    const query = findPersonalizedContentSF(queryName);
    const brand = useSelector(getBrandName) || '1800flowers';

    const browserUUID = useBrowserUUID();

    const jwt = useGetAccessTokenSafely();
    // skip until jwt is loading
    const skip = Boolean(!jwt || !browserUUID);
    // set sessionLoginState according to user login state
    const userType = useSelector(getUserType);
    const userAttributes = [];
    userAttributes.push({ name: 'sessionLoginState', value: userType === 'R' ? 'true' : 'false' });

    // code block for sending hasAccountCookie key under userAttributes
    if (isSessionHasAccount) userAttributes.push({ name: 'hasAccountCookie', value: 'true' });

    return useQuery(query, {
        variables: (skip) ? { skip } : {
            // use default variables directly
            brand,
            environment: GRAPHQL_ENV,
            user: {
                anonymousId: browserUUID,
            },
            userAttributes,
            targeting: {
                interactionName,
                ...targeting,
            },
        },
        context: { api: 'personalizedContent', jwt },
        fetchPolicy: 'no-cache',
        skip,
        ...apolloOptions,
    });
};

export default useSalesforcePersonalizedContentQuery;
