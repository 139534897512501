/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/* eslint-disable object-curly-newline */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable global-require */

/*
    mbp-account-ui redux

    Checklist for mbp-account-ui operations requiring middleware (all files in src/state/reducers):

            Declare _REQUEST action in ActionType.js
            Define request action in EventDispatch.js, including callList of middleware method(s)
            Write middleware method(s) in Services.js
            Write "Action...Success" reducer in Action.js to handle results of middleware
        and finally:
            write code that dispatches to EventDispatcher(yourAction(params)), where needed

    The gory details:

        Code dispatches to a redux action using definition from EventDispatcher.js
        This action's constant is defined in ActionType.js and ends in _REQUEST.

            Example:  FETCH_ADDRESS_BOOK_REQUEST   'mbp-account-ui/address-book/get/REQUEST'

        EventDispatcher's action includes the parameters passed, as well as callList of middleware to be invoked.

            Example:    fetchAddressBook: recipients => ({
                            type: ActionType.FETCH_ADDRESS_BOOK_REQUEST,
                            recipients,
                            callList: ['fetchAddressBook', 'fetchCountryData'],
                        })

        When dispatched, this action is picked up by the WatcherSaga which then invokes WorkerSagas to invoke the middleware.
        Only _REQUEST actions are picked up by WatcherSaga.  All other actions only picked up by Reducers.js
        A _REQUEST action may additionally be picked up by Reducer which sets state to "Busy" while middleware is running.

        The middleware methods live in state/ducks/Services.js and are methods of the Services class.
        If the middleware retrieves data (as it does in addressbook example), the middleware often stores the data in session
        storage in addition to returning the data.

        If any of the middleware methods fail, a new failure action is formed by replacing /REQUEST with /FAILURE

            Example:    mbp-account-ui/address-book/get/FAILURE         (note: no ACTION constant defined for this)

        This FAILURE action is picked up by Reducers.js which typically just sets a not-ready status in state.

        If all middleware methods succeed, a new success action is formed by replacing /REQUEST with /LOADED.
        The data for this new action is an aggregate of all data retrieved by middleware.

            Example:    type: mbp-account-ui/address-book/get/LOADED       (note: no ACTION constant defined for this)
                        recipients,
                        **addressbook and country data fields filled by middleware**

        This action is picked up by reducer which transforms the original ACTION constant by converting to Capitalize
        case and substituting "REQUEST" with "Success".  This new "Action...Success" is a method in the
        Action class defined in state/reducers/Action.js, and is then called

            Example:    FETCH_ADDRESS_BOOK_REQUEST becomes  FetchAddressBookSuccess

        The Action method then takes action based on the middleware data received.  E.g. If middleware retrieves data,
        the Action method is likely a reducer that stores this data in state.

        ------------------

        Note: Because under this scheme components sometimes do not have direct dependencies that are
              detected by React, the toggleState hack exists.  By setting toggleState to !toggleState at
              the end of an "Action...Success" method, it forces component(s) to update.

*/

import mbpLogger from 'mbp-logger';
import qs from 'qs';
import mbpUtil from 'mbp-api-util';

import * as auth from '../../../../../state/ducks/Member/ducks/Auth/Plugin/auth/auth';

import restClient from '../../../../helpers/restClient';
// import Auth from '../../../../../state/ducks/Member/ducks/Auth/Plugin/Auth/Auth';
import LocationTypes from '../constants/LocationTypes';
import States from '../constants/States';
import { Countries } from '../constants/Countries';
import APOFPO from '../constants/APOFPO';
import Relationships from '../constants/Relationships';
import CreditCardTypes from '../constants/CreditCardTypes';
import Helper from './Helper';
import { uuidv4 } from '../../../../helpers/uuid';
import { decipher } from '../../utils/crypto';

import http from '../../services/httpService';
import getSiteConfig from '../../utils/getSiteConfig';
import {
    findSkusByFullPartNumbersQL,
    getProductDetailByItemNumberQL,
    findProductRecommendationsSF,
} from '../../services/graphQL';
import { isEmpty } from '../../utils/object';
import {
    addressBooks,
} from '../../__mocks__/fakeData/recipientData';
import configENV from '../../config';
import { dateMMMMDDYYYY } from '../../utils/dateString';
import cancelOrderAPI from '../../../../../apis/order-apis/cancelOrder';
import { processReplaceOrder } from '../../../../../apis/order-apis/processReplaceOrder';
import { processRefundOrder } from '../../../../../apis/order-apis/processRefundOrder';
import { processPartialRefund } from '../../../../../apis/order-apis/processPartialRefund';
// import CSIGuardRail from '../../../../../apis/order-apis/csiGuardRail';
import { subscriptionRecipientUpdateApi } from '../../../../../apis/account-apis/subscriptionRecipientUpdateApi';
import checkProductAvailability from '../../../../../apis/order-apis/checkProductAvailability';
import deliveryDatesAPI from '../../../../../apis/order-apis/fetchDeliveryDates';
import submitModifyOrderAPI from '../../../../../apis/order-apis/modifyOrder';
import { fetchSubscriptionGiftMessageApi } from '../../../../../apis/account-apis/fetchSubscriptionGiftMessageApi';
import isProductAvailableOn from '../../../../../apis/order-apis/isProductAvailableOn';
import hdEditableCheck from '../../../../../apis/order-apis/hdEditableCheck';
import updatePassportAutoRenewalStatusApi from '../../../../../apis/account-apis/updatePassportAutoRenewalStatus';
import fetchOrderDetailsAPI from '../../../../../apis/order-apis/fetchOrderDetails';
import fetchOrderDetailsV2API from '../../../../../apis/order-apis/fetchOrderDetailsV2';
import massCancelUserSubscriptionsApi from '../../../../../apis/account-apis/massCancelUserSubscriptionsApi';
import validateCaptchaApi from '../../../../../apis/account-apis/validateCaptchaApi';
import updateSubscriptionFrequencyApi from '../../../../../apis/account-apis/updateSubscriptionFrequencyApi';
import addASMAgentNotesApi from '../../../../../apis/asm-apis/addASMAgentNotesApi';
import QasClient from '../../../../../apis/qas-address-search-apis/qas-address-search-apis';
import fetchASMAgentNotesApi from '../../../../../apis/asm-apis/fetchASMAgentNotesApi';
import fetchSubscriptionApi from '../../../../../apis/account-apis/fetchSubscriptionsApi';
import cancelSubscriptionApi from '../../../../../apis/account-apis/cancelSubscriptionApi';
import updateSubscriptionApi from '../../../../../apis/account-apis/updateSubscriptionApi';
import cancellationSurveyApi from '../../../../../apis/account-apis/cancellationSurveyApi';
import fetchPassportAutoRenewalStatusApi from '../../../../../apis/account-apis/fetchPassportAutoRenewalStatus';
import thumbsUpForDeliveryImageAPI from '../../../../../apis/order-apis/thumbsUpForDeliveryImage';
import cancelPassportMembership from '../../../../../apis/account-apis/cancelPassportMembership';
import cartServices from '../../../../../apis/cart-service-apis/cartServices';
import { getCountryCodeFromTwoToThreeDigit } from '../../../../../state/ducks/Checkout/helpers/countryCode';
import swapSubscriptionApi from '../../../../../apis/account-apis/swapSubscriptionApi';
import fetchSubscriptionByStatusApi from '../../../../../apis/account-apis/fetchSubscriptionByStatusApi';
import getPriceFromEngine from '../../../../../apis/product-apis/priceEngine';
import { getBaseCode } from '../../../../helpers/tracking/product/dataLayerProductHelpers';
import useBrowserUUID from '../../../../helpers/useBrowserUUID';

const {
    PERSIST_KEY, AUTH_LOGIN_URL, SHOP_THE_SITE_KEY, SESSION_STORAGE_ENABLED, SESSION_STORAGE_EXPIRES, GENERIC_ERROR,
} = configENV;

const GRAPHQL_ENV = process.env.APP_GRAPHQL_ENV ? process.env.APP_GRAPHQL_ENV : process.env.NODE_ENV;

const brandsEmailOpt = {
    FB: '18B',
    FBQ: '18B',
    HD: 'HND',
    WF: 'WLF',
    SY: 'STK',
    STY: 'STK',
    BRY: 'BERRIES',
    VC: 'VTC',
};
export default class Services {
    constructor(siteConfig, featureFlags, accessToken, deviceType = 'desktop', userInfo) {
        this.siteConfig = siteConfig || getSiteConfig();
        this.partnumPrefix = this.siteConfig['partnum-prefix'];
        this.brandName = this.siteConfig['brand-id'];
        this.domain = this.siteConfig.domain;
        this.accessToken = accessToken;
        this.featureFlags = featureFlags || {};
        this.user = Helper.getChannelUser(deviceType, this.domain, this.featureFlags['is-web-using-new-channel-codes'], this.featureFlags['is-frontend-calling-new-wismo']); // 30001-HD, 30001-18F, 30002-CCO, etc
        const { idToken } = Helper.getAuthentication();
        this.userProfile = this.siteConfig.userProfile || http.getUserProfile(accessToken, idToken, null);
        this.userInfo = userInfo;
        this.enterpriseId = Helper.getEnterpriseId();
        this.wcEnv = {
            // // Mock server host name
            // host: 'localhost:3011',
            // protocol: 'http',
            protocol: 'https',
            prefix: '/r/api',
            rootUri: '/',
            brand: this.domain,
        };
    }

    storeShopTheSiteConfirmationInSessionStorage = (data) => {
        const { object } = data;
        let { dontShowModal: noShopTheSiteConfirmation } = data;
        const { ContactId } = this.userProfile;
        const accountStorage = sessionStorage.getItem(PERSIST_KEY);
        let storageJson = { [ContactId]: {} };
        if (accountStorage) {
            storageJson = JSON.parse(accountStorage);
            if (storageJson
                && storageJson[ContactId]
                && typeof storageJson[ContactId].noShopTheSiteConfirmation !== 'undefined'
                && !noShopTheSiteConfirmation) {
                ({ noShopTheSiteConfirmation } = storageJson[ContactId]);
            }
        }
        Object.keys(object).forEach((key) => {
            storageJson[ContactId] = {
                ...storageJson[ContactId],
                [key]: object[key],
                noShopTheSiteConfirmation,
                timestamp: new Date().getTime(),
            };
        });
        sessionStorage.setItem(PERSIST_KEY, JSON.stringify(storageJson));
        return {
            data: { ...object, noShopTheSiteConfirmation },
        };
    };

    setObjectInSessionStorage = (data) => {
        const { object } = data;
        const { ContactId } = this.userProfile;
        const accountStorage = sessionStorage.getItem(PERSIST_KEY);
        let storageJson = { [ContactId]: {} };
        if (accountStorage) {
            storageJson = JSON.parse(accountStorage);
        }
        Object.keys(object).forEach((key) => {
            storageJson[ContactId] = {
                ...storageJson[ContactId],
                [key]: object[key],
                timestamp: new Date().getTime(),
            };
        });
        sessionStorage.setItem(PERSIST_KEY, JSON.stringify(storageJson));
        return {
            data: { ...object },
        };
    };

    getObjectInSessionStorage = (key) => {
        const { ContactId } = this.userProfile;
        const accountStorage = sessionStorage.getItem(PERSIST_KEY);
        let storageJson = { [ContactId]: {} };
        if (accountStorage) {
            storageJson = JSON.parse(accountStorage);
            if (storageJson && storageJson[ContactId]) {
                return storageJson[ContactId][key];
            }
        }
        return '';
    };

    getchShopTheSiteModalState = () => {
        let noShopTheSiteConfirmation = false;
        const { ContactId } = this.userProfile;
        const accountStorage = sessionStorage.getItem(PERSIST_KEY);
        let storageJson = { [ContactId]: {} };
        if (accountStorage) {
            storageJson = JSON.parse(accountStorage);
            if (storageJson
                && storageJson[ContactId]
                && typeof storageJson[ContactId].noShopTheSiteConfirmation !== 'undefined') {
                ({ noShopTheSiteConfirmation } = storageJson[ContactId]);
            }
        }
        return {
            data: { noShopTheSiteConfirmation },
        };
    };

    linkUserToGiftList = async (data) => {
        const { accessToken } = this.userProfile;
        const { customerNumber, zipCode, orderNumber, last4CC } = data;
        const pageData = { url: '' };
        const recipients = {};
        const totalPage = 1;
        const currentPage = 1;
        let errorMessage;
        let errorName = 'customerNumber';
        const error = { errorMessage: GENERIC_ERROR, type: 'modal', name: errorName };
        const errorResponse = {
            data: { occasions: [], occasionId: '', recipients, totalPage, currentPage, pageData, error, isGiftListUser: false, isGiftListLoaded: true },
        };
        this.wcEnv.rootUri = '/';
        let resourcePage;
        if (customerNumber && zipCode) {
            errorMessage = "Sorry, we couldn't find your customer number with the provided zip code on the system.";
            resourcePage = `giftlist/customer/${customerNumber}/zipcode/${zipCode}`;
        } else if (customerNumber) {
            // Link by customerNumber from magic link
            errorMessage = "Sorry, we couldn't find your customer number with the provided zip code on the system.";
            resourcePage = `giftlist/customer/${customerNumber}`;
        } else if (orderNumber && last4CC) {
            errorMessage = "Sorry, we couldn't find your order number with the last 4 digits from the credit card used.";
            errorName = 'orderNumber';
            resourcePage = `giftlist/ordernumber/${orderNumber}/cc/${last4CC}`;
        } else {
            return { ...errorResponse };
        }
        return restClient.getFacade(this.wcEnv, resourcePage, accessToken)
            .then(async (response) => {
                if (response.status === 200) {
                    if (!isEmpty(response.data) && response.data[0].id) {
                        const { data: result } = await this.fetchGiftListRecipients({}, true);
                        if (isEmpty(result.occasions) && result.error) {
                            result.error.errorMessage = errorMessage;
                            result.error.type = 'inline';
                            result.error.name = errorName;
                        } else if (!isEmpty(result.occasions) && result.error) {
                            result.error.errorMessage = '';
                        }
                        return {
                            data: { ...result },
                        };
                    }
                    errorResponse.data.error.errorMessage = errorMessage;
                    errorResponse.data.error.type = 'inline';
                    errorResponse.data.error.name = errorName;
                }
                return {
                    ...errorResponse,
                };
            })
            .catch((err) => {
                const { response } = err;
                if (response && response.status === 412 && errorResponse.data.error) {
                    errorResponse.data.error.errorMessage = errorMessage;
                    errorResponse.data.error.type = 'inline';
                    errorResponse.data.error.name = errorName;
                }
                return { ...errorResponse };
            });
    };

    fetchGiftListOccasions = async (payload) => {
        const { ContactId } = this.userProfile;
        const { accessToken } = this.userProfile;
        const resourcePage = 'giftlist';
        // Get data from sessionStorage
        const accountStorage = sessionStorage.getItem(PERSIST_KEY);
        let storageJson = { [ContactId]: {} };
        if (accountStorage) {
            storageJson = JSON.parse(accountStorage);
            if (storageJson
                && storageJson[ContactId]
                && storageJson[ContactId].giftListOccasions
                && storageJson[ContactId].timestamp) {
                const nowTime = new Date().getTime();
                const isExpired = Math.round((nowTime - storageJson[ContactId].timestamp) / 1000) > SESSION_STORAGE_EXPIRES;
                if (!isExpired) {
                    return storageJson[ContactId].giftListOccasions;
                }
            }
        }

        this.wcEnv.rootUri = '/';
        // this.wcEnv.host = 'localhost:3011';
        // this.wcEnv.protocol = 'http';
        const response = await restClient.getFacade(this.wcEnv, resourcePage, accessToken, {});
        delete response.headers;
        delete response.request;
        delete response.config;
        const occasionList = [];

        if (response.status === 200 && !isEmpty(response.data)) {
            response.data.forEach((item) => {
                occasionList.push(item);
            });
        }

        // Update sessionStorage
        if (SESSION_STORAGE_ENABLED === 'on') {
            if (!storageJson[ContactId]) {
                storageJson = { [ContactId]: {} };
            }
            storageJson[ContactId] = {
                ...storageJson[ContactId],
                giftListOccasions: [...occasionList],
                timestamp: new Date().getTime(),
            };
            sessionStorage.setItem(PERSIST_KEY, JSON.stringify(storageJson));
        }

        if (payload?.isDirect) {
            return { data: { occasions: [...occasionList] } };
        }
        return [...occasionList];
    };

    fetchSalesforceGiftListRecs = async (accessToken, recipientEntry, occasionName) => {
        this.wcEnv.rootUri = '/';
        const salesforceResourceURL = 'customer-personalize/graphql?GiftListProductRecommendations';
        const query = findProductRecommendationsSF('GiftListProductRecommendations');
        const brandName = this.domain || '1800flowers';
        const SF_GRAPHQL_ENV = mbpUtil.getEnv('APP_GRAPHQL_ENV'); // reading env from mbp-pwa-ui container

        const { item: { currentItem } } = recipientEntry;
        let anchorProductPartNumber = null;
        // sending anchor product partNumber from giftlist legacy API for generating salesforce recs accordingly.
        if (currentItem?.fullParentPartNumber) anchorProductPartNumber = currentItem?.fullParentPartNumber;

        const userAttributes = [
            {
                name: 'currentBrand',
                value: brandName,
            },
            {
                name: 'isSympathy',
                value: 'false',
            },
            {
                name: 'sessionLoginState',
                value: 'true',
            },
            {
                name: 'gfOccasion',
                value: occasionName?.toLowerCase(),
            },
        ];

        if (anchorProductPartNumber) {
            userAttributes.push({
                name: 'referenceProductId',
                value: anchorProductPartNumber,
            });
        }

        const sfPayload = {
            query,
            operationName: 'GiftListProductRecommendations',
            variables: {
                brand: brandName,
                environment: SF_GRAPHQL_ENV,
                user: {
                    anonymousId: useBrowserUUID(),
                    zipCode: '',
                },
                userAttributes,
                targeting: {
                    interactionName: `${brandName} - Get Campaign - Giftlist Registered`,
                    pageType: 'gift-list',
                },
            },
        };

        let salesforceResponseWithExtraInfo = [];
        let salesforceAPIResponse = {};
        let salesforceResponseRecs = [];
        let isVariantFlow = false;

        try {
            // Get recs data from salesforce API
            const salesforceResponse = await restClient.postFacade(this.wcEnv, salesforceResourceURL, accessToken, sfPayload);

            salesforceAPIResponse = salesforceResponse.data.data.productRecommendationsSF;
            salesforceResponseRecs = salesforceAPIResponse?.products || [];
            const abTestGroup = salesforceAPIResponse?.campaign?.campaignResponses[0]?.userGroup || '';
            isVariantFlow = abTestGroup && abTestGroup.toLowerCase() !== 'control';

            if (!isVariantFlow) return { isVariantFlow, salesforceResponseWithExtraInfo, salesforceAPIResponse: { ...salesforceAPIResponse, products: [recipientEntry?.item?.currentItem, ...recipientEntry?.item?.recommendation] } };

            salesforceResponseWithExtraInfo = salesforceResponseRecs?.map((recProduct) => {
                const {
                    image: { name: imagename, path: imagePath }, skuPriceRange, seo,
                } = recProduct;
                const retailPrice = skuPriceRange.retail?.[0].value;
                const salePrice = skuPriceRange.sale?.[0].value;
                const productImagePath = recProduct?.image ? `${imagePath}${imagename}x.jpg` : '';
                const baseCode = getBaseCode(recProduct?.partNumber, true);
                return ({
                    ...recProduct,
                    id: recProduct.partNumber,
                    content: recProduct?.description,
                    largeThumbNailImage: productImagePath,
                    name: recProduct?.name,
                    sku: baseCode,
                    listPrice: retailPrice,
                    offerPrice: salePrice,
                    partNumber: baseCode,
                    status: recProduct?.shipDateMsg,
                    parentCatalogEntryId: recProduct?.parentCatalogEntryId,
                    seoproductDisplayURL: seo?.url,
                    itemContents: recProduct?.itemContents,
                    oGPartNumber: recProduct?.partNumber,
                });
            });
            // return existing giftlist product recs if sf api not returned any product even if camapign returned as variant/default
            if (isVariantFlow && !salesforceResponseRecs?.length) return { isVariantFlow: false, salesforceResponseWithExtraInfo, salesforceAPIResponse };
            return { isVariantFlow, salesforceResponseWithExtraInfo, salesforceAPIResponse };
        } catch (error) {
            mbpLogger.logError({
                function: 'Services.fetchSalesforceGiftListRecs',
                appName: process.env.npm_package_name,
                module: 'mbp-account-ui',
                message: error,
            });
            return { isVariantFlow, salesforceResponseWithExtraInfo, salesforceAPIResponse: { ...salesforceAPIResponse, products: [recipientEntry?.item?.currentItem, ...recipientEntry?.item?.recommendation] } };
        }
    };

    fetchPricesFromPricingEngine = async (accessToken, partNumbers) => {
        let pricessData = [];
        const isPassportMember = this.user?.userType === 'R';
        const isPassportUser = this.user?.userRole === 'P';

        const customerTypeValue = [{
            name: 'CUSTOMER_TYPE',
            value: 'Registered',
        }];

        if (isPassportMember && isPassportUser) {
            customerTypeValue.push({
                name: 'CUSTOMER_TYPE',
                value: 'Passport',
            });
        }

        const payload = {
            enterpriseId: this.enterpriseId,
            products: partNumbers.map((n) => ({
                partNumber: n,
            })),
            variants: customerTypeValue,
        };

        const priceEngineDataResponse = await getPriceFromEngine({}, accessToken, payload, this.featureFlags['is-price-engine-batching-enabled'], true);

        try {
            // Get recs data from price engine API
            const { products } = priceEngineDataResponse.data;
            pricessData = products?.map((product) => {
                const retailPriceData = product?.prices?.find((priceData) => priceData.type === 'retail');
                const salePriceData = product?.prices?.find((priceData) => priceData.type === 'sale');
                return { partNumber: product.partNumber, retailPrice: retailPriceData?.value, salePrice: salePriceData?.value, productType: product?.productType, prices: product?.prices, brand: product?.brand };
            });
            return pricessData;
        } catch (error) {
            mbpLogger.logError({
                appName: process.env.npm_package_name,
                jsError: error,
                message: `Gift List Recommendations: fetch prices failed...${error.message}`,
                enterpriseId: this.enterpriseId,
            });
            return pricessData;
        }
    };

    getProductsInCart = async (orderId) => {
        // const { accessToken } = this.userProfile;
        const accessToken = await auth.getAccessTokenSafely();

        let totalItemInCart;
        if (orderId) {
            totalItemInCart = await cartServices.getCartOrderDetails({
                wcEnv: this.wcEnv,
                jwtToken: accessToken,
                cartId: orderId || '',
            });
        }
        delete totalItemInCart.headers;
        delete totalItemInCart.request;
        delete totalItemInCart.config;
        if (totalItemInCart.status === 200 && !isEmpty(totalItemInCart.data)) {
            const itemsInCartByAddressId = Helper.getGiftListItemFromCart(totalItemInCart);
            return {
                itemsInCartByAddressId,
                itemsInCart: totalItemInCart?.data,
            };
        }
        return {};
    };

    // giftListLoginWithCustNbr = async (data) => {
    //     const { auth_client_id: clientId, 'brand-id': brandId } = this.siteConfig;
    //     const { custNbr, register } = data;
    //     Helper.setSessionStorageObject('common', { custNbr }, false);
    //     const routeBack = '/account/gift-list';
    //     let auth0;
    //     if (register === 'Y') {
    //         auth0 = new Auth(clientId, brandId, { routeBack, custNbr, register });
    //     } else {
    //         auth0 = new Auth(clientId, brandId, { routeBack, custNbr });
    //     }
    //     await auth0.login();
    //     return {
    //         data,
    //     };
    // };

    // auth0Login = async (data) => {
    //     const { auth_client_id: clientId, 'brand-id': brandId } = this.siteConfig;
    //     const { register } = data;
    //     const routeBack = '/account/gift-list';
    //     let auth0;
    //     if (register === 'Y') {
    //         auth0 = new Auth(clientId, brandId, { routeBack, register });
    //     } else {
    //         auth0 = new Auth(clientId, brandId, { routeBack });
    //     }
    //     await auth0.login();
    //     return {
    //         data: {
    //             auto0Login: true,
    //         },
    //     };
    // };

    fetchGiftListRecipients = async (data, forceRefresh = false) => {
        const { ContactId } = this.userProfile;
        const accessToken = await auth.getAccessTokenSafely();
        const { setOccasionId, id: occasionIdFromURL, orderId, newPage } = data;
        let occasionId;
        let pageData = { url: '' };
        let recipients = {};
        let totalPage = 1;
        let noShopTheSiteConfirmation = false;
        let currentPage = newPage || 1;
        let error = { errorMessage: '' };
        let salesforceAPIResponseFromSession = {};
        let occasions = await this.fetchGiftListOccasions().catch((err) => err);
        if (occasions.response && occasions.response.status === 401) {
            pageData.url = AUTH_LOGIN_URL;
            return {
                data: { occasions: [], occasionId: '', recipients, totalPage, currentPage, pageData, error, isGiftListUser: false, isGiftListLoaded: true },
            };
        }

        if (occasionIdFromURL) {
            occasionId = occasionIdFromURL;
        } else {
            try {
                occasionId = setOccasionId || occasions[0].id;
            } catch (ex) {
                // User doesn't have gift list yet
                const custNbr = Helper.getSessionStorageObject('common', 'custNbr', false);
                // Reset the custNbr
                Helper.setSessionStorageObject('common', { custNbr: '' }, false);
                if (!isEmpty(custNbr)) {
                    const linkingResult = await this.linkUserToGiftList({ customerNumber: custNbr });
                    if (linkingResult && !isEmpty(linkingResult.data) && linkingResult.data.occasionId && linkingResult.data.occasions) {
                        ({ data: { occasionId, occasions } } = linkingResult);
                    } else {
                        // TODO: should show the error message modal
                        ({ error } = linkingResult.data);
                        error.errorMessage = `Sorry, we couldn't find the customer number ${custNbr} on the system. Please try with one of the provided options or contact customer service for assistance.`;
                        return {
                            data: { occasions: [], occasionId: '', recipients, totalPage, currentPage, pageData, error, isGiftListUser: false, isGiftListLoaded: true },
                        };
                    }
                } else {
                    // After creating new account, Auth0 redirected users back to /account/gift-list
                    // and the Gift List data need to be reloaded, so we need to set isGiftListLoaded: false to avoid the flashing screen
                    return {
                        data: { occasions: [], occasionId: '', recipients, totalPage, currentPage, pageData, error, isGiftListUser: false, isGiftListLoaded: false },
                    };
                }
            }
        }

        // Reset the custNbr
        Helper.setSessionStorageObject('common', { custNbr: '' }, false);
        // Redirect to /account/gift-list if occasionId not found
        if (occasions.findIndex((occ) => occ.id === occasionId) === -1) {
            pageData = {
                url: '/account/gift-list',
            };
            return {
                data: { occasions, occasionId: occasions[0].id, recipients, totalPage, currentPage, pageData, error, isGiftListUser: true, isGiftListLoaded: true },
            };
        }

        let productsInCart = {};
        if (orderId) {
            ({ itemsInCartByAddressId: productsInCart } = await this.getProductsInCart(orderId));
        }

        // Get data from sessionStorage
        const accountStorage = sessionStorage.getItem(PERSIST_KEY);
        let storageJson = { [ContactId]: {} };
        // disable caching(session storage) if salesforce giftlist recommendations enabled
        if (!this.featureFlags['is-salesforce-gift-list-recommendations-enabled']) {
            if (accountStorage) {
                storageJson = JSON.parse(accountStorage);
                if (storageJson && storageJson[ContactId]) {
                // Get previous shop the site confirmation modal's state
                    ({ noShopTheSiteConfirmation } = storageJson[ContactId]);
                    if (
                        !forceRefresh
                    && storageJson[ContactId][`giftList_${occasionId}_${currentPage}`]
                    && storageJson[ContactId].timestamp) {
                        const nowTime = new Date().getTime();
                        const isExpired = Math.round((nowTime - storageJson[ContactId].timestamp) / 1000) > SESSION_STORAGE_EXPIRES;
                        if (!isExpired) {
                            const storageRecipients = storageJson[ContactId][`giftList_${occasionId}_${currentPage}`];
                            ({ recipients, totalPage, salesforceAPIResponseFromSession } = storageRecipients);
                            return {
                                data: { occasions, orderId, occasionId, recipients, totalPage, currentPage, pageData, productsInCart, noShopTheSiteConfirmation, error, isGiftListUser: true, isGiftListLoaded: true, salesforceAPIResponse: salesforceAPIResponseFromSession },
                            };
                        }
                    }
                }
            }
        }
        const resourcePage = `giftlist/${occasionId}/?page=${currentPage}&rnd=${Math.random()}`;
        // Mock Server URL
        // const resourcePage = `get-recipients/${occasionId}`;
        //
        // Set this.wcEnv.rootUri to '/'; not sure why the rootUri has been changed
        this.wcEnv.rootUri = '/';
        // Get Gift List Data by page number
        const response = await restClient.getFacade(this.wcEnv, resourcePage, accessToken, {});
        recipients[occasionId] = [];
        let responseData = [];
        let availableProducts = [];
        const skuLists = [];
        let dataSource = '';
        let dataBrandCode = '';
        let isBigGiftlist = false;
        const partNumbersList = [];
        try {
            // eslint-disable-next-line no-underscore-dangle
            responseData = response.data._embedded?.recipient || [];
            dataSource = response.data.source || '';
            dataBrandCode = response.data.brandCode || '';
            totalPage = response.data.total;
            currentPage = response.data.page;
            isBigGiftlist = response.data?._embedded?.isBigGiftlist;
        } catch (ex) {
            // Gift list isn't ready for user or user doesn't have gift list yet
            return {
                data: { occasions: [], orderId, occasionId: '', isBigGiftlist, recipients: {}, totalPage, currentPage, pageData, productsInCart, error, isGiftListUser: false, isGiftListLoaded: true },
            };
        }

        const filteredOccasion = occasions?.length && occasions.find((occasion) => occasion.id === occasionIdFromURL) ? occasions.find((occasion) => occasion.id === occasionIdFromURL) : occasions?.[0];
        const occasionName = filteredOccasion ? filteredOccasion?.name : '';
        const dynamicSFRecipientData = [];
        const salesforceAPIResponses = [];
        if (this.featureFlags['is-salesforce-gift-list-recommendations-enabled'] && response.status === 200) {
        // eslint-disable-next-line no-restricted-syntax
            for (const recipientEntry of responseData) {
            // eslint-disable-next-line no-await-in-loop
                const { isVariantFlow = false, salesforceResponseWithExtraInfo = [], salesforceAPIResponse = {} } = !isEmpty(responseData) ? await this.fetchSalesforceGiftListRecs(accessToken, recipientEntry, occasionName) : {};
                dynamicSFRecipientData.push({ isVariantFlow, salesforceResponseWithExtraInfo, recipientItemId: recipientEntry.item.orderItemId });
                salesforceAPIResponses.push({ ...salesforceAPIResponse, recipientItemId: recipientEntry.item.orderItemId });
            }
        }

        const states = Helper.refactorStateData(States);
        const countries = Helper.refactorObjectData(Countries);
        if (response.status === 200 && !isEmpty(responseData)) {
            responseData.forEach((item) => {
                const { profile, item: { currentItem, orderItemId, recommendation, previousItemMetadata, greeting }, deliveryDate, holidayCode, holidayDesc } = item;
                const selectedCountry = countries.filter((c) => c.id === profile.country);
                // Set default country to US if it's empty or undefined
                const country = selectedCountry.length ? selectedCountry[0] : ({ id: 'US', name: 'United States' });
                const selectedState = states[country.id].filter((c) => c.id === profile.state);
                // Set empty state data to prevent dropdown error
                const state = selectedState.length ? selectedState[0] : ({ id: '', name: '' });
                let giftArrived = '';
                // If isNewRecipient === true, it means no Gift List history for this recipient,
                // and we will call a recipient API to update and get delivery date right after adding to cart.
                // In 4.1, we can't proceed to checkout page due to missing delivery date after adding to cart on Gift List.
                // Not sure about 4.2, but this issue has been raised in 4.1.
                let isNewRecipient = true;
                try {
                    const { requestedShipDate } = previousItemMetadata;
                    let date = null;
                    // the date can come as object or string.
                    if (typeof requestedShipDate === 'string') {
                        date = requestedShipDate;
                    } else {
                        const { monthValue: month, dayOfMonth: day, year } = requestedShipDate;
                        date = new Date(year, month - 1, day);
                    }

                    giftArrived = dateMMMMDDYYYY(date);
                    isNewRecipient = false;
                    // eslint-disable-next-line no-empty
                } catch (ex) { }
                // The xOrderAttrValues object will be used to track Gift List product while adding to cart in 4.2
                const xOrderAttrValues = {
                    giftHistoryOrderItemId: orderItemId,
                    giftHistoryOccassionCode: occasionId,
                    // eslint-disable-next-line no-underscore-dangle
                    giftHistoryPromCode: response.data._embedded?.giftlistCode || '',
                    // eslint-disable-next-line no-underscore-dangle
                    giftHistoryCustomerNumber: response.data._embedded?.customerNumber || '',
                    // eslint-disable-next-line no-underscore-dangle
                    giftHistoryRecipientCustomerNumber: profile.recipientNumber || '',
                };
                // Refactor recipient's address object
                const address = {
                    addressId: profile?.addressId,
                    street: profile?.address1,
                    apt: profile?.address2,
                    company: profile?.organizationName,
                    city: profile?.city,
                    location: {
                        name: profile?.locationType || 'Residence',
                    },
                    state,
                    country,
                    zipcode: profile.zipCode,
                };
                const { isVariantFlow = false, salesforceResponseWithExtraInfo = [] } = dynamicSFRecipientData?.length && dynamicSFRecipientData.find((sfRecipient) => sfRecipient.recipientItemId === orderItemId) ? dynamicSFRecipientData.find((sfRecipient) => sfRecipient.recipientItemId === orderItemId) : {};
                availableProducts = isVariantFlow ? salesforceResponseWithExtraInfo : [currentItem, ...recommendation];
                // Refactor products list object for each recipient
                const products = [];
                const greetingMessage = greeting || '';

                availableProducts.forEach((rec) => {
                    // Validating here currentItem is equal to previousItemMetaData for send same gift
                    const isCurrentItem = isVariantFlow ? salesforceResponseWithExtraInfo[0]?.sku : currentItem?.sku || '';
                    const isPreviousItem = previousItemMetadata?.partNumber || '';
                    const isCurrentSkuAvail = isPreviousItem?.includes(isCurrentItem);
                    let hasItemSendPrevious = false;
                    if (isCurrentItem === isPreviousItem || isCurrentSkuAvail) {
                        hasItemSendPrevious = true;
                    }
                    const price = rec.listPrice;
                    const discountPrice = rec.offerPrice < price ? rec.offerPrice : '';
                    const crossedPrice = discountPrice ? price : '';
                    // Collecting product SKU to get product custom attributes such as wine, personalized, or CYO...
                    let productPartNumber = '';
                    // Keep the original SKU if it starts with tpf_ or cco_
                    if (/^(cco_|tpf_)/.test(rec.sku)) productPartNumber = rec.sku;
                    // Force to use -I- insead of -P- in the findSkusByFullPartNumbers API call
                    else if (/(-I-|-P-)/.test(rec.sku)) productPartNumber = rec.sku.replace(/(-I-|-P-)/, '-I-');
                    else {
                        const [brandCodeProd] = rec.fullParentPartNumber?.split('-') || [''];
                        // Convert partnum-prefix from XXXX-P- to XXXX-I-
                        productPartNumber = `${brandCodeProd ? `${brandCodeProd}-I-` : this.partnumPrefix}${rec.sku}`;
                    }
                    skuLists.push(productPartNumber);
                    partNumbersList.push(isVariantFlow ? rec.oGPartNumber : rec.fullParentPartNumber);
                    const product = {
                        id: rec.partNumber,
                        content: rec.description,
                        crossedPrice,
                        discountPrice,
                        image: rec.largeThumbNailImage,
                        name: rec.name,
                        price,
                        sku: rec.sku,
                        customAttributes: {}, // used to identify wine, personalized, or CYO product
                        xOrderAttrValues, // will be used to track Gift List product while adding to cart in 4.2
                        partNumber: rec.partNumber,
                        productPartNumber,
                        status: rec.shipDateMsg,
                        parentCatalogEntryId: rec.parentCatalogEntryId,
                        seoproductDisplayURL: rec.seoproductDisplayURL,
                        itemContents: rec.itemContents,
                        hasItemSendPrevious,
                        oGPartNumber: isVariantFlow ? rec.oGPartNumber : rec.fullParentPartNumber,
                    };
                    if (rec.priceRules) {
                        product.priceRules = rec.priceRules;
                    }

                    products.push(product);
                });

                // Recipient object
                const recipient = {
                    id: orderItemId,
                    addressId: profile.addressId,
                    AddressBookEntryId: '', // TODO: need to import AddressBookEntryId from addressBook if exists
                    address,
                    products,
                    addToCart: '',
                    firstname: profile.firstName,
                    lastname: profile.lastName,
                    occasionId,
                    orderItemId,
                    giftArrived,
                    sourceAttributes: {
                        brandCode: dataBrandCode,
                        source: dataSource,
                    },
                    isNewRecipient,
                    phoneNumber: profile.phoneNumber,
                    previousItemMetadata,
                    deliveryDate,
                    holidayCode,
                    holidayDesc,
                    greetingMessage,
                    type: 'link',
                    currentItempartNumber: isVariantFlow ? salesforceResponseWithExtraInfo[0]?.sku : currentItem.partNumber,
                };
                // Recipients object / list by Occasion
                recipients[occasionId].push(recipient);
            });
        }

        const skuPricesData = this.featureFlags['is-dynamic-pricing-enabled'] ? await this.fetchPricesFromPricingEngine(null, [...new Set(partNumbersList)]) : [];

        this.wcEnv.rootUri = '/';
        const resourceURL = 'aggregator/graphql';
        const payload = {
            query: findSkusByFullPartNumbersQL([...new Set(skuLists)], GRAPHQL_ENV),
        };
        const customAttr = await restClient.postFacade(this.wcEnv, resourceURL, accessToken, payload);
        // customAttributes is used to identify wine, personalized, or CYO product
        let customAttributes = [];
        try {
            // eslint-disable-next-line no-underscore-dangle
            customAttributes = customAttr.data.data.findSkusByFullPartNumbers;
        } catch (ex) {
            // Couldn't get custom attribute, but we'll return the valide data anyway
            return {
                data: { occasions, orderId, occasionId, recipients, totalPage, currentPage, pageData, productsInCart, noShopTheSiteConfirmation, error, isGiftListUser: true, isGiftListLoaded: true, salesforceAPIResponse: salesforceAPIResponses },
            };
        }
        if (customAttr.status === 200 && !isEmpty(customAttributes)) {
            customAttributes.forEach(async (attr) => {
                const id = attr?.partNumber;
                // Keep only the last part of `id` of the customAttr: tpf_XXXXXX, cco_XXXXXX, 1019-P-13332X => 13332X
                const sku = id ? id.replace(/^(tpf_|cco_|1020-|1019-|1018-|1002-|1012-|1025-|\d+-P-|\d+-I-)/, '') : '';
                // Update product with custom attribute
                recipients[occasionId].forEach((rec, i) => {
                    const productIndexBySku = rec.products.findIndex((p) => p.sku === sku);
                    const { isVariantFlow = false } = dynamicSFRecipientData?.length && dynamicSFRecipientData.find((sfRecipient) => sfRecipient.recipientItemId === rec.id) ? dynamicSFRecipientData.find((sfRecipient) => sfRecipient.recipientItemId === rec.id) : {};
                    if (productIndexBySku > -1) {
                        const filteredInfoBySku = skuPricesData?.find((priceData) => priceData.partNumber === recipients[occasionId][i]?.products[productIndexBySku]?.oGPartNumber);
                        recipients[occasionId][i].products[productIndexBySku].customAttributes = attr;
                        if (isVariantFlow || filteredInfoBySku) {
                            const retailPrice = filteredInfoBySku ? filteredInfoBySku.retailPrice : attr?.prices?.find((priceData) => priceData.type === 'retail')?.value;
                            const salePrice = filteredInfoBySku ? filteredInfoBySku.salePrice : attr?.prices?.find((priceData) => priceData.type === 'sale')?.value;
                            const feedPrice = retailPrice;
                            const feedDiscountPrice = salePrice < feedPrice ? salePrice : '';
                            const feedCrossedPrice = feedDiscountPrice ? feedPrice : '';
                            recipients[occasionId][i].products[productIndexBySku].itemContents = attr?.longDescription;
                            recipients[occasionId][i].products[productIndexBySku].description = attr?.parentProduct?.shortDescription;
                            recipients[occasionId][i].products[productIndexBySku].content = attr?.parentProduct?.shortDescription;
                            if (feedPrice) recipients[occasionId][i].products[productIndexBySku].price = feedPrice;
                            recipients[occasionId][i].products[productIndexBySku].discountPrice = feedDiscountPrice;
                            recipients[occasionId][i].products[productIndexBySku].crossedPrice = feedCrossedPrice;
                            recipients[occasionId][i].products[productIndexBySku].productType = filteredInfoBySku ? filteredInfoBySku.productType : '';
                            recipients[occasionId][i].products[productIndexBySku].prices = filteredInfoBySku ? filteredInfoBySku.prices : '';
                            recipients[occasionId][i].products[productIndexBySku].brand = filteredInfoBySku ? filteredInfoBySku.brand : '';
                        }
                    }
                });
            });
        }

        // disable caching(session storage) if salesforce giftlist recommendations enabled
        if (!this.featureFlags['is-salesforce-gift-list-recommendations-enabled']) {
        // Update sessionStorage
            if (!storageJson[ContactId]) {
                storageJson = { [ContactId]: {} };
            }
            const giftListRecipientsPage1 = storageJson[ContactId][`giftList_${occasionId}_1`];
            if (forceRefresh && !isEmpty(giftListRecipientsPage1)) {
                const totalPageInSession = giftListRecipientsPage1.totalPage;
                let index = 0;
                while (index < totalPageInSession) {
                    delete storageJson[ContactId][`giftList_${occasionId}_${index + 1}`];
                    index += 1;
                }
            }
            storageJson[ContactId] = {
                ...storageJson[ContactId],
                [`giftList_${occasionId}_${currentPage}`]: { recipients, totalPage, salesforceAPIResponseFromSession: salesforceAPIResponses },
                timestamp: new Date().getTime(),
            };
            sessionStorage.setItem(PERSIST_KEY, JSON.stringify(storageJson));
        }
        return {
            data: { occasions, orderId, occasionId, isBigGiftlist, recipients, totalPage, currentPage, pageData, productsInCart, noShopTheSiteConfirmation, error, isGiftListUser: true, isGiftListLoaded: true, salesforceAPIResponse: salesforceAPIResponses },
        };
    };

    fetchGiftListRecipientsByPage = async (data) => {
        const { occasionId: id, newPage, orderId } = data;
        const { data: responseData } = await this.fetchGiftListRecipients({ id, newPage }, false);
        const { occasionId, recipients } = responseData;
        const recipientsByPage = recipients[occasionId];

        let productsInCart = {};
        if (orderId) {
            ({ itemsInCartByAddressId: productsInCart } = await this.getProductsInCart(orderId));
        }

        const { data: { noShopTheSiteConfirmation } } = this.getchShopTheSiteModalState();
        return {
            data: {
                ...responseData,
                recipientsByPage,
                noShopTheSiteConfirmation,
                orderId,
                productsInCart,
            },
        };
    }

    fetchGiftListAddressBook = () => ({
        data: { addressBooks },
        error: false,
    });

    fetchUserProfile = () => {
        const { userProfile } = this;
        return {
            data: { userProfile },
            error: false,
        };
    };

    updateUserProfile = ({ updatedUserProfile }) => {
        const { userProfile } = this;
        return {
            data: { userProfile: { ...userProfile, ...updatedUserProfile } },
        };
    };

    fetchAddressBook = async (data) => {
        const { ContactId } = this.userProfile;

        const accessToken = await auth.getAccessTokenSafely();
        const pageCount = this.featureFlags['how-many-address-book-pagecount'] || 500;

        // Return exising data
        let { recipients = [] } = data;
        const { isAddressAddedFromCheckout } = data;
        let statusCode;

        // Adding  isAddressAddedFromCheckout=false condition because if isAddressAddedFromCheckout=true
        // we need to fetch all the latest address list so need to call api, because existing recipients would not be updated.
        if (recipients.length && !isAddressAddedFromCheckout) {
            return {
                data: { recipients, ContactId },
                error: false,
            };
        }
        try {
            // Get data from sessionStorage
            const accountStorage = sessionStorage.getItem(PERSIST_KEY);
            let storageJson = { [ContactId]: {} };
            if (accountStorage && SESSION_STORAGE_ENABLED === 'on') {
                storageJson = JSON.parse(accountStorage);
                if (storageJson
                    && storageJson[ContactId]
                    && storageJson[ContactId].recipients
                    && storageJson[ContactId].timestamp) {
                    const nowTime = new Date().getTime();
                    const isExpired = Math.round((nowTime - storageJson[ContactId].timestamp) / 1000) > SESSION_STORAGE_EXPIRES;
                    if (!isExpired) {
                        return {
                            data: {
                                recipients: storageJson[ContactId].recipients,
                                ContactId,
                            },
                            error: false,
                        };
                    }
                }
            }
            recipients = [];
            // Get addressbook if data doesn't exist
            const { pageNumber = 0 } = data;
            this.wcEnv.rootUri = '/';
            const resourcePage = `addressbook/contacts/${ContactId}/entries?pageNumber=${pageNumber}&pageCount=${pageCount}&IncludeAddresses=true`;
            const response = await restClient.getFacade(this.wcEnv, resourcePage, accessToken, {});
            const { AddressBookEntries } = response.data.Result;
            // Filter out duplicate recipients with the same address
            AddressBookEntries.forEach((entry) => {
                const index = recipients.findIndex((r) => r.FirstName === entry.FirstName && r.LastName === entry.LastName);
                const hasRecipientAddresses = recipients[index]?.Addresses?.[0] || '';
                const hasEntriesAddresses = entry?.Addresses?.[0] || '';
                if (index >= 0) {
                    const isSameAddressLineOne = hasRecipientAddresses?.AddressLineOne?.trim().toUpperCase() === hasEntriesAddresses?.AddressLineOne?.trim().toUpperCase();
                    const isSameAddressLineTwo = hasRecipientAddresses?.AddressLineTwo?.trim().toUpperCase() === hasEntriesAddresses?.AddressLineTwo?.trim().toUpperCase();
                    const isSameAddressType = hasRecipientAddresses?.AddressType?.trim().toUpperCase() === hasEntriesAddresses?.AddressType?.trim().toUpperCase();
                    const isSameCity = hasRecipientAddresses?.City?.trim().toUpperCase() === hasEntriesAddresses?.City?.trim().toUpperCase();
                    const isSameStateProvince = hasRecipientAddresses?.StateProvince?.trim().toUpperCase() === hasEntriesAddresses?.StateProvince?.trim().toUpperCase();
                    const isSameCountry = hasRecipientAddresses?.Country?.trim().toUpperCase() === hasEntriesAddresses?.Country?.trim().toUpperCase();
                    const recipientPostalCode = hasRecipientAddresses?.PostalCode?.trim().replace(/-\d{4}$/, '').toUpperCase(); // remove the zip code extension
                    const entryPostalCode = hasEntriesAddresses?.PostalCode?.trim().replace(/-\d{4}$/, '').toUpperCase(); // remove the zip code extension
                    const isSamePostalCode = recipientPostalCode === entryPostalCode;
                    if (
                        !(isSameAddressLineOne && isSameAddressLineTwo && isSameAddressType && isSameCity && isSameStateProvince && isSameCountry && isSamePostalCode)
                    ) {
                        recipients.push(entry);
                    }
                } else {
                    recipients.push(entry);
                }
            });
            // Update sessionStorage
            if (SESSION_STORAGE_ENABLED === 'on') {
                if (!storageJson[ContactId]) {
                    storageJson = { [ContactId]: {} };
                }
                storageJson[ContactId] = {
                    ...storageJson[ContactId],
                    recipients,
                    timestamp: new Date().getTime(),
                };
                sessionStorage.setItem(PERSIST_KEY, JSON.stringify(storageJson));
            }
            return {
                data: { recipients, ContactId },
                error: false,
            };
        } catch (error) {
            statusCode = error.response?.status;
            mbpLogger.logError({
                function: 'Services.fetchAddressBook',
                appName: process.env.npm_package_name,
                module: 'mbp-account-ui',
                message: error,
            });
            return {
                data: { recipients: [], ContactId, statusCode },
                error: true,
            };
        }
    };

    fetchBillingAddress = async (data) => {
        const { ContactId } = this.userProfile;
        const accessToken = await auth.getAccessTokenSafely();
        let statusCode;
        // Return existing data
        let { billingAddress, wallet } = data;
        // This should be inside of a component that does not need to fire the API call if there is data
        // Some components need fresh data every time this function is called
        // if (billingAddress.length) {
        //     return {
        //         data: { billingAddress, wallet, ContactId },
        //         error: false,
        //     };
        // }

        // Get data from sessionStorage
        const accountStorage = sessionStorage.getItem(PERSIST_KEY);
        let storageJson = { [ContactId]: {} };
        if (accountStorage && SESSION_STORAGE_ENABLED === 'on') {
            storageJson = JSON.parse(accountStorage);
            if (storageJson
                && storageJson[ContactId]
                && storageJson[ContactId].billingAddress
                && storageJson[ContactId].wallet
                && storageJson[ContactId].timestamp) {
                const nowTime = new Date().getTime();
                const isExpired = Math.round((nowTime - storageJson[ContactId].timestamp) / 1000) > SESSION_STORAGE_EXPIRES;
                if (!isExpired) {
                    return {
                        data: {
                            billingAddress:
                                storageJson[ContactId].billingAddress,
                            wallet: storageJson[ContactId].wallet,
                            ContactId,
                        },
                        error: false,
                    };
                }
            }
        }

        try {
            if (ContactId) {
                // Get billing address data from billingwallet if data deosn't exist
                this.wcEnv.rootUri = '/';
                const billingResourcePage = `addressbook/composite/billingwallet/${ContactId}`;
                const response = await restClient.getFacade(this.wcEnv, billingResourcePage, accessToken, {});
                const {
                    billingAddress: { billingAddresses },
                } = response.data.Result;
                billingAddress = [...billingAddresses];
            }
        } catch (err) {
            statusCode = err.response?.status;
            mbpLogger.logError({
                function: 'fetchBillingAddress',
                appName: process.env.npm_package_name,
                module: 'Services.js',
                jsError: err,
                message: 'fetchBillingFailed - Load Billing Address Azure Api Failed',
            });
        }

        try {
            // Get wallet data from GCP endpoint
            const walletResourcePage = `wallets/${ContactId}/cards`;
            const walletResponse = await restClient.getFacade(this.wcEnv, walletResourcePage, accessToken, {});
            const {
                result,
            } = walletResponse.data;

            // eslint-disable-next-line prefer-destructuring
            if (result) wallet = result.wallet;
        } catch (err) {
            statusCode = err.response?.status;
            mbpLogger.logError({
                function: 'fetchBillingAddress',
                appName: process.env.npm_package_name,
                module: 'Services.js',
                jsError: err,
                message: 'fetchWalletFailed - Load Wallet Gcp Api Failed',
            });
        }

        // Update sessionStorage
        if (SESSION_STORAGE_ENABLED === 'on') {
            if (!storageJson[ContactId]) {
                storageJson = { [ContactId]: {} };
            }
            storageJson[ContactId] = {
                ...storageJson[ContactId],
                billingAddress,
                wallet,
                timestamp: new Date().getTime(),
            };
            sessionStorage.setItem(PERSIST_KEY, JSON.stringify(storageJson));
        }
        return {
            data: { billingAddress, wallet, ContactId, statusCode },
            error: false,
        };
    };

    fetchOrdersByEmail = async ({ featureFlags, page }) => {
        let { email, ContactId, isAuthenticated } = this.userProfile;
        // getting user info from members state if we are not getting it from userProfile
        const { profile } = this.userInfo;
        if (!email) email = profile?.email;
        if (!ContactId) ContactId = profile?.contactId;
        if (!isAuthenticated) isAuthenticated = this.userInfo.isAuthenticated;
        const { user } = this;
        const accessToken = await auth.getAccessTokenSafely();
        const internalToken = (user.indexOf('30001') > -1) ? mbpUtil.getEnv('ROSS_TOKEN_DESKTOP') : mbpUtil.getEnv('ROSS_TOKEN_MOBILE');

        // Get data from sessionStorage
        const accountStorage = sessionStorage.getItem(PERSIST_KEY);
        let storageJson = { [ContactId]: {} };
        if (accountStorage) {
            storageJson = JSON.parse(accountStorage);
            // ROSS orders cache
            if (storageJson
                && storageJson[ContactId]
                && storageJson[ContactId].ROSSOrders
                && featureFlags['is-ross-session-cache-on']
                && storageJson[ContactId].timestamp) {
                const nowTime = new Date().getTime();
                const isExpired = Math.round((nowTime - storageJson[ContactId].timestamp) / 1000) > SESSION_STORAGE_EXPIRES;
                if (!isExpired) {
                    return {
                        data: {
                            ROSSOrders: storageJson[ContactId].ROSSOrders,
                            ContactId,
                        },
                        isErrorResponse: false,
                        error: { errorMessage: '' },
                    };
                }
            }
        }
        let errorMessage = '';
        let totalPages = 1;
        const orderList = [];
        const ROSSOrders = [];
        const isROSSEnabled = featureFlags['is-order-page-using-ross-endpoint'] || false;
        const wwwFlag = featureFlags['is-ssp-using-www-endpoints'] || false;
        const isROSSWebGatesOn = featureFlags['is-ross-web-gates-on'] || false;
        const isUsingNewROSSObject = featureFlags['is-using-new-ross-object'] || false;
        let statusCode;

        // check email before sending payload to ROSS API
        if (!email || (email && !/\S+@\S+\.\S+/.test(email))) {
            return {
                data: { orderList, ContactId, isErrorResponse: true, error: { errorMessage: 'error fetching ROSS orders by email: invalid email format' } },
            };
        }
        if (isAuthenticated) {
            // Get data from orderList if data doesn't exist
            if (isROSSEnabled) {
                const env = mbpUtil.getEnv('APP_GRAPHQL_ENV');
                try {
                    if (email && /\S+@\S+\.\S+/.test(email)) {
                        if (wwwFlag) {
                            const ROSSData = {
                                user,
                                email,
                            };
                            const headers = isROSSWebGatesOn ? ({
                                'internal-token': internalToken,
                            }) : null;
                            if (isUsingNewROSSObject) {
                                await fetchOrderDetailsV2API(accessToken, ROSSData, headers, '', page)
                                    .then(({ data }) => {
                                        if (Array.isArray(data.result)) {
                                            if (data.totalPages > 1) {
                                                totalPages = data.totalPages;
                                            }
                                            data.result.forEach((ordersList) => {
                                                ordersList.orders.forEach((order) => {
                                                    order.uuid = (order.uuid && /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(order.uuid)) ? (order.uuid) : uuidv4();
                                                    ROSSOrders.push(order);
                                                });
                                            });
                                        }
                                    })
                                    .catch((err) => {
                                        statusCode = err.response?.status;
                                        errorMessage = `error fetching ROSS orders by email v2: ${err}`;
                                        mbpLogger.logError({
                                            function: 'Services.fetchOrdersByEmail',
                                            appName: process.env.npm_package_name,
                                            module: 'mbp-account-ui',
                                            message: errorMessage,
                                        });
                                    });
                            } else {
                                await fetchOrderDetailsAPI(accessToken, ROSSData, headers)
                                    .then(({ data }) => {
                                        if (Array.isArray(data.orders)) {
                                            data.orders.forEach((order) => {
                                                order.uuid = (order.uuid && /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(order.uuid)) ? (order.uuid) : uuidv4();
                                                ROSSOrders.push(order);
                                            });
                                        }
                                    })
                                    .catch((err) => {
                                        statusCode = err.response?.status;
                                        errorMessage = `error fetching ROSS orders by email: ${err}`;
                                        mbpLogger.logError({
                                            function: 'Services.fetchOrdersByEmail',
                                            appName: process.env.npm_package_name,
                                            module: 'mbp-account-ui',
                                            message: errorMessage,
                                        });
                                    });
                            }
                        } else {
                            const endpoint = (env === 'production' || env === 'uat2')
                                ? 'https://fast-api.800-flowers.net/r/api/retention/selfservice/order/status'
                                : 'https://fast-api-staging.800-flowers.net/r/api/retention/selfservice/order/status';
                            const headers = {
                                'content-type': 'application/json',
                                accept: 'application/json',
                                authorization: `Bearer ${accessToken}`,
                            };
                            if (isROSSWebGatesOn) {
                                headers['internal-token'] = internalToken;
                            }
                            await fetch(endpoint, {
                                method: 'post',
                                headers,
                                mode: 'cors',
                                cache: 'no-cache',
                                body: JSON.stringify({ user, email }),
                            })
                                .then((data) => data.json())
                                .then((data) => {
                                    if (Array.isArray(data.orders)) {
                                        data.orders.forEach((order) => {
                                            order.uuid = (order.uuid && /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(order.uuid)) ? (order.uuid) : uuidv4();
                                            ROSSOrders.push(order);
                                        });
                                    }
                                })
                                .catch((err) => {
                                    errorMessage = `error fetching ROSS orders by email: ${err}`;
                                    mbpLogger.logError({
                                        function: 'Services.fetchOrdersByEmail',
                                        appName: process.env.npm_package_name,
                                        module: 'mbp-account-ui',
                                        message: errorMessage,
                                    });
                                });
                        }

                        // ROSS orders cache
                        if (featureFlags['is-ross-session-cache-on'] && ROSSOrders.length > 0) {
                            storageJson[ContactId] = {
                                ...storageJson[ContactId],
                                orderList,
                                ROSSOrders,
                                timestamp: new Date().getTime(),
                            };
                        }
                        sessionStorage.setItem(PERSIST_KEY, JSON.stringify(storageJson));

                        // // Mock ROSS fetchOrderByEmail
                        // const mockData = require('../__mocks__/ROSS_fetchOrderByEmail.json');
                        // if (Array.isArray(mockData.orders)) {
                        //     ROSSOrders.length = 0;
                        //     mockData.orders.forEach((order) => {
                        //         order.uuid = (order.uuid && /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(order.uuid)) ? (order.uuid) : uuidv4();
                        //         ROSSOrders.push(order);
                        //     });
                        // }
                        // // end Mock ROSS data

                        return {
                            data: { ROSSOrders, ContactId, isErrorResponse: !!(errorMessage), error: { errorMessage }, statusCode, totalPages },
                        };
                    }
                    return {
                        data: { orderList, ContactId, isErrorResponse: true, error: { errorMessage: 'error fetching ROSS orders by email: invalid email format' } },
                    };
                } catch (err) {
                    errorMessage = `error fetching ROSS order list: ${err}`;
                    mbpLogger.logError({
                        function: 'Services.fetchOrdersByEmail',
                        appName: process.env.npm_package_name,
                        module: 'mbp-account-ui',
                        message: errorMessage,
                    });
                    return {
                        data: { orderList, ContactId, isErrorResponse: true, error: { errorMessage }, statusCode },
                    };
                }
            }
        }

        // Update sessionStorage
        if (!storageJson[ContactId]) {
            storageJson = { [ContactId]: {} };
        }
        if (SESSION_STORAGE_ENABLED === 'on') {
            storageJson[ContactId] = {
                ...storageJson[ContactId],
                orderList,
                timestamp: new Date().getTime(),
            };
        }

        sessionStorage.setItem(PERSIST_KEY, JSON.stringify(storageJson));

        if (isROSSEnabled && !ROSSOrders.length) {
            // alert for ROSS dashboard
            mbpLogger.logInfo({
                appName: process.env.npm_package_name,
                function: 'fetchOrdersByEmail',
                message: 'Falling back to GQL from ROSS',
            });
        }
        return {
            data: { orderList, ContactId, isErrorResponse: !!(errorMessage), error: { errorMessage }, statusCode },
        };
    };

    cancelOrder = async ({ data }) => {
        const accessToken = await auth.getAccessTokenSafely();
        data.user = this.user;
        try {
            const cancelResponse = await cancelOrderAPI(accessToken, data);
            return { data: { cancelResponse } };
        } catch (error) {
            const message = 'Error while cancelling order';
            mbpLogger.logError({
                function: 'Services.cancelOrder',
                appName: process.env.npm_package_name,
                module: 'mbp-account-ui',
                message,
                error,
            });
            return { data: { cancelResponseError: error } };
        }
    };

    //  CSIGuardRailCheck = async ({ data }) => {
    //      const accessToken = await auth.getAccessTokenSafely();
    //      const { guardRailBody } = data;
    //      let guardRailResponse;
    //      let guardRailError;
    //      try {
    //          guardRailResponse = await CSIGuardRail(accessToken, guardRailBody);
    //      } catch (error) {
    //          const message = 'Error during CSI GuardRail Check';
    //          mbpLogger.logError({
    //              function: 'Services.CSIGuardRailCheck',
    //              appName: process.env.npm_package_name,
    //              module: 'mbp-account-ui',
    //              message,
    //              error,
    //          });
    //          guardRailError = error;
    //      }
    //      return {
    //          data: {
    //              guardRailResponse,
    //              guardRailError,
    //          },
    //      };
    //  }

    storeOrderDetailsInSessionStorage = (ROSSOrder) => {
        const { ContactId } = this.userProfile;
        // Update sessionStorage
        const storageJson = { [ContactId]: {} };
        storageJson[ContactId] = {
            ...storageJson[ContactId],
            ROSSOrder,
            timestamp: new Date().getTime(),
        };
        sessionStorage.setItem(PERSIST_KEY, JSON.stringify(storageJson));
    }

    getOrderDetailsFromSessionStorage = () => {
        const { ContactId } = this.userProfile;
        // Get data from sessionStorage
        const accountStorage = sessionStorage.getItem(PERSIST_KEY);
        let storageJson = { [ContactId]: {} };
        if (accountStorage) {
            storageJson = JSON.parse(accountStorage);
            // Get ROSSOrder
            if (storageJson
                && storageJson[ContactId]
                && storageJson[ContactId].ROSSOrder
                && storageJson[ContactId].timestamp) {
                const ROSSOrder = storageJson[ContactId].ROSSOrder;
                // Remove ROSSOrder from sessionStorage after one time used
                delete storageJson[ContactId].ROSSOrder;
                sessionStorage.setItem(PERSIST_KEY, JSON.stringify(storageJson));
                return ROSSOrder;
            }
        }
        return {};
    }

    fetchOrderDetails = async (data) => {
        // TODO: update userProfile or deprecate
        const { accessToken, user, userInfo, userProfile, featureFlags } = this;
        const internalToken = (user.indexOf('30001') > -1) ? mbpUtil.getEnv('ROSS_TOKEN_DESKTOP') : mbpUtil.getEnv('ROSS_TOKEN_MOBILE');
        const { uuid, options, isAuthenticatedStatus } = data;
        let { orderNumber, zipCode, billingZipCode } = data;
        const orderDetails = {};
        let errorMessage = '';
        const error = { errorMessage };
        const { profile } = userInfo;
        const email = profile?.email || userProfile?.email;
        const ContactId = profile?.contactId || userProfile?.ContactId;
        // I -> In transit
        // D/U -> Delivered
        // C -> Cancelled
        // W and all other status -> Order Received
        if (options) {
            if (uuid && options.zip && options.zip !== '0' && !orderNumber) {
                zipCode = decipher(uuid.slice(0, 8))(options.zip);
            }
            if (uuid && options.zip && options.zip !== '0' && !orderNumber) {
                billingZipCode = decipher(uuid.slice(0, 8))(options.zip);
            }
            if (uuid && options.order && !orderNumber) {
                orderNumber = decipher(uuid.slice(0, 8))(options.order.replace(/-/g, ''));
            }
        }

        const isROSSWebGatesOn = featureFlags['is-ross-web-gates-on'] || false;

        let ROSSOrder = {};
        // check orderNumber before sending payload to ROSS API
        if (!orderNumber) {
            errorMessage = 'Unable to obtain order details for this order.';
            return {
                // if no orderNumber, users will get Oops Sorry page.
                data: { orderDetails, ContactId, orderDataLoaded: true, isErrorResponse: true, error: { errorMessage }, redirectURL: '' },
            };
        }
        const rossData = isAuthenticatedStatus
            ? { user, orderNumber, email }
            : { user, orderNumber, recipientZipCode: zipCode, billingZipCode };
        const headers = isROSSWebGatesOn ? ({
            'internal-token': internalToken || '',
        }) : null;
        try {
            // We use a NOT empty `options` object to indentify if it's a order details
            if (!isAuthenticatedStatus && !isEmpty(options)) {
                // Get ROSSOrder from sessionStorage to avoid double ROSS call
                // while redirecting users from guest order page to order details page.
                // ROSSOrder data is stored in sessionStorage for one time use, and it'll be removed afterward
                ROSSOrder = this.getOrderDetailsFromSessionStorage();
                if (!isEmpty(ROSSOrder)) {
                    return {
                        data: { ROSSOrder, ContactId, options, isErrorResponse: false, error, orderDataLoaded: true, redirectURL: '' },
                    };
                }
            }
            if (isAuthenticatedStatus && !rossData.email) {
                rossData.email = 'Email Not Found-Services1307';
            }

            const isUsingNewROSSObject = featureFlags['is-using-new-ross-object'] || false;
            if (isUsingNewROSSObject) {
                const ROSSOrderResponse = await fetchOrderDetailsV2API(accessToken, rossData, headers, email);
                ROSSOrder = ROSSOrderResponse.data;
                ROSSOrder.uuid = uuid;
            } else {
                const ROSSOrderResponse = await fetchOrderDetailsAPI(accessToken, rossData, headers, email);
                ROSSOrder = ROSSOrderResponse.data;
                ROSSOrder.uuid = uuid;
            }
            if (!isEmpty(ROSSOrder)) {
                // return empty `orderDetails` if ROSS returns:
                // {"synced":true,"message":"No record found"}
                // or {"orderID":"3702653772","brandID":"ATLAS","orderLevelStatus":"","modifyOrder":"false",
                // "modifiedDateTime":"2021-06-01 21:59:08","orders":[],"synced":true}
                if (isEmpty(ROSSOrder.orders)) {
                    errorMessage = 'Unable to obtain order details for this order.';
                    const returnObject = {
                        orderDetails,
                        ContactId,
                        orderDataLoaded: true,
                        isErrorResponse: false, // DO NOT show as the error reponse that leads to Oops error page.
                        error: { errorMessage },
                        redirectURL: '',
                    };
                    if (isAuthenticatedStatus) {
                        // If users already logged in and they couldn't get any order that associated to their account,
                        // we'll redirect them to /account/orders page
                        returnObject.redirectURL = '/account/orders';
                    }
                    return {
                        data: { ...returnObject },
                    };
                }
            }
        } catch (err) {
            const statusCode = err.response?.status;
            errorMessage = `Order Status Error - email: ${this.userProfile.email} isAuthenticatedStatus: ${isAuthenticatedStatus} Status: ${statusCode}`;
            mbpLogger.logError({
                function: 'Services.fetchOrderDetails',
                appName: process.env.npm_package_name,
                module: 'mbp-account-ui',
                message: errorMessage,
            });
            const returnObject = {
                orderDetails,
                ContactId,
                orderDataLoaded: true,
                isErrorResponse: true,
                error: { errorMessage },
                statusCode,
                redirectURL: '',
            };
            // if guest account, check zipCode and billingZipCode before sending payload to ROSS API
            if (!isAuthenticatedStatus && !(zipCode && billingZipCode)) {
                errorMessage = '';
                // if guest account doesn't provide a zipCode and/or billingZipCode, users will be redirected to GUEST /account/orders page.
                // we set `isErrorResponse: false` to prevent users from seeing the Oops error page
                const redirectURL = '/account/orders';
                returnObject.redirectURL = redirectURL;
                returnObject.isErrorResponse = false;
            }
            return {
                data: { ...returnObject },
            };
        }

        // ROSSOrder = require('../__mocks__/ROSS_FPT_CONFIRMED_1.json');
        // ROSSOrder = require('../__mocks__/ROSS_FPT_IN_TRANSIT.json');
        // ROSSOrder = require('../__mocks__/ROSS_FPT_DELIVERED_2.json');
        // ROSSOrder = require('../__mocks__/ROSS_GPT_CONFIRMED.json');
        // ROSSOrder = require('../__mocks__/ROSS_GPT_IN_TRANSIT.json');
        // ROSSOrder = require('../__mocks__/ROSS_GPT_DELIVERED.json');

        const orderDataLoaded = !isEmpty(ROSSOrder);

        // Temprary store ROSSOrder data in sessinStorage to avoid double ROSS call
        // while redirecting users from guest order tracking to order details page.
        // We use an empty `options` object to indentify if it's a guest order tracking homepage
        if (!isAuthenticatedStatus && orderDataLoaded && isEmpty(options)) {
            // Added a timestamp into the ROSSOrder object to differenciate between the ROSS API response
            // and ROSS object getting from the sessionStorage; it's useful for the unit test
            const timestamp = new Date().getTime();
            this.storeOrderDetailsInSessionStorage({
                ...ROSSOrder,
                timestamp,
            });
        }

        return {
            data: { ROSSOrder, ContactId, options, isErrorResponse: false, error, orderDataLoaded, redirectURL: '' },
        };

        // THIS IS HACKABLE; BACKEND NEEDS TO RESTRICT THE `getOrdersDetails By OrderNumber` CALL FROM GUEST ACCOUNT
    };

    replaceOrder = async ({ data }) => {
        const accessToken = await auth.getAccessTokenSafely();
        const { orderNumber, tiEmail, startTime, msgFlorist, problemCode, detailCode, reasonCode, resCode,
            suggestResCode, statusCode, faultCode, errorCode, suggestedResId, resolutionBrand, brandCode } = data;

        let replaceResponse;
        let replaceResponseError;
        const payload = {
            orderNumber,
            user: this.user,
            ti_email: tiEmail,
            start_time: startTime,
            msg_florist: msgFlorist,
            problem_code: problemCode,
            detail_code: detailCode,
            reason_code: reasonCode,
            res_code: resCode,
            suggest_res_code: suggestResCode,
            status_code: statusCode,
            fault_code: faultCode,
            error_code: errorCode,
            suggested_res_id: suggestedResId,
            resolution_brand: resolutionBrand,
            brandCode,
        };

        try {
            replaceResponse = await processReplaceOrder(this.wcEnv, accessToken, payload, null, null);

            // Mock Response with 5 second delay
            // console.log('replacement payload', payload);
            // replaceResponse = await new Promise((resolve) => setTimeout(() => resolve(replaceResponse = { replaceOrder: true }), 5000));
        } catch (error) {
            replaceResponseError = 'Error while processing replacement';
            mbpLogger.logError({
                function: 'Services.replaceOrder',
                appName: process.env.npm_package_name,
                module: 'mbp-account-ui',
                message: replaceResponseError,
                error,
            });
        }

        return {
            data: { replaceResponse, replaceResponseError },
        };
    };

    refundOrder = async ({ data }) => {
        const accessToken = await auth.getAccessTokenSafely();
        let refundResponse;
        let refundResponseError = '';
        const payload = { ...data, user: this.user };
        try {
            refundResponse = await processRefundOrder(this.wcEnv, accessToken, payload, null, null);
            // console.log('refund payload', payload);
            // refundResponse = await new Promise((resolve) => setTimeout(() => resolve({ refundOrder: true, message: 'Valid message' }), 5000));
        } catch (error) {
            refundResponseError = 'Error while processing refund';
            mbpLogger.logError({
                function: 'Services.refundOrder',
                appName: process.env.npm_package_name,
                module: 'Services API Call',
                message: refundResponseError,
                error,
            });
        }

        return {
            data: { refundResponse, refundResponseError },
        };
    };

    partialRefund = async ({ data }) => {
        const accessToken = await auth.getAccessTokenSafely();
        const { orderNumber, tiEmail, startTime, msgFlorist, problemCode, detailCode, reasonCode, resCode,
            suggestResCode, statusCode, faultCode, errorCode, suggestedResId, resolutionBrand } = data;

        let partialRefundResponse;
        let partialRefundResponseError;
        const payload = {
            orderNumber,
            user: this.user,
            ti_email: tiEmail,
            start_time: startTime,
            msg_florist: msgFlorist,
            problem_code: problemCode,
            detail_code: detailCode,
            reason_code: reasonCode,
            res_code: resCode,
            suggest_res_code: suggestResCode,
            status_code: statusCode,
            fault_code: faultCode,
            error_code: errorCode,
            suggested_res_id: suggestedResId,
            resolution_brand: resolutionBrand,
        };

        try {
            partialRefundResponse = await processPartialRefund(this.wcEnv, accessToken, payload, null, null);

            // Mock Response with 5 second delay
            // console.log('partial refund payload', payload);
            // partialRefundResponse = await new Promise((resolve) => setTimeout(() => resolve(partialRefundResponse = { message: 'Valid Message' }), 5000));
        } catch (error) {
            partialRefundResponseError = error;
            const message = 'Error while processing partial refund';
            mbpLogger.logError({
                function: 'Services.partialRefund',
                appName: process.env.npm_package_name,
                module: 'mbp-account-ui',
                message,
                error,
            });
        }
        return {
            data: { partialRefundResponse, partialRefundResponseError },
        };
    };

    checkProductAvailability = async ({ data }) => {
        const accessToken = await auth.getAccessTokenSafely();
        let isAvailable;
        let availabilityError;

        try {
            isAvailable = await checkProductAvailability(this.wcEnv, accessToken, data, null, null);
        } catch (err) {
            const message = 'Error while checking for product availability';
            mbpLogger.logError({
                function: 'Services.checkProductAvailability',
                appName: process.env.npm_package_name,
                module: 'mbp-account-ui',
                message,
                error: err,
            });
            availabilityError = message;
        }
        return {
            data: { isAvailable, availabilityError },
        };
    }

    fetchEmailAddress = () => {
        const { email } = this.userProfile;
        return {
            data: { email },
        };
    };

    fetchEmailAddressFromId = async ({ indivId, accessToken }) => {
        const resourcePage = `giftlist/email?indiv_id=${indivId}`;
        const response = await restClient.getFacade(this.wcEnv, resourcePage, accessToken);
        const error = { errorMessage: GENERIC_ERROR };
        delete response.headers;
        delete response.request;
        delete response.config;
        try {
            const { data: { email } } = response;
            if (email) error.errorMessage = '';
        } catch (err) {
            console.error(err);
            error.errorMessage = err.description;
        }
        const codeSentToEmail = Helper.getSessionStorageObject('common', 'isCodeSentToEmail');
        const isCodeSentToEmail = (!isEmpty(codeSentToEmail) || typeof codeSentToEmail === 'boolean') ? codeSentToEmail : false;
        return {
            // eslint-disable-next-line object-curly-newline
            data: { indivId, isCodeSentToEmail, response, error },
        };
    }

    // sendCodeToEmail = async (data) => {
    //     const { email, custNbr } = data;
    //     const { auth_passwordless_client_id: passwordlessClientId, 'brand-id': brandId } = this.siteConfig;
    //     const error = { errorMessage: GENERIC_ERROR, type: 'inline', name: 'sendCodeToEmail' };
    //     // const routeBack = '/gifthistory-home-gift-baskets';
    //     const routeBack = '/account/gift-list';
    //     const auth0 = new Auth(passwordlessClientId, brandId, { custNbr, routeBack });
    //     let response = {};
    //     // Store custNbr in storage for calling add giftList by customer# after returning from Auth0
    //     Helper.setSessionStorageObject('common', { custNbr }, false);
    //     Helper.setSessionStorageObject('common', { isCodeSentToEmail: true });
    //     await auth0.handlePasswordlessStart({
    //         connection: 'email',
    //         send: 'code',
    //         email,
    //     })
    //         .then((result) => {
    //             response = result.data;
    //             error.errorMessage = '';
    //         })
    //         .catch((err) => {
    //             console.error('Error sending code: ', err);
    //             Helper.setSessionStorageObject('common', { isCodeSentToEmail: false });
    //         });
    //     return {
    //         data: { ...response, error },
    //     };
    // }

    // auth0PasswordlessLogin = async () => {
    //     const { auth_client_id: clientId, 'brand-id': brandId } = this.siteConfig;
    //     const routeBack = '/';
    //     const magicLogin = 'Y';
    //     const auth0 = new Auth(clientId, brandId, { routeBack, magicLogin });
    //     await auth0.login();
    //     return {
    //         data: {
    //             auto0Login: true,
    //         },
    //     };
    // }

    // submitGiftListVerificationCode = async (data) => {
    //     const { email, code } = data;
    //     const { auth_passwordless_client_id: passwordlessClientId, 'brand-id': brandId } = this.siteConfig;
    //     const error = { errorMessage: GENERIC_ERROR, type: 'field', name: 'verificationCode' };
    //     let response = {};
    //     const auth0 = new Auth(passwordlessClientId, brandId);
    //     await auth0.handlePasswordlessLogin({
    //         connection: 'email',
    //         verificationCode: code,
    //         email,
    //     })
    //         .then((result) => {
    //             response = result.data;
    //             error.errorMessage = '';
    //         })
    //         .catch((err) => {
    //             console.error('Error submitting code: ', err);
    //             if (err.error === 'access_denied') {
    //                 error.errorMessage = 'Invalid or expired code, please try again.';
    //             } else {
    //                 error.errorMessage = err.description;
    //             }
    //         });
    //     return {
    //         data: { ...response, error },
    //     };
    // }

    fetchEmailPreference = async () => {
        let { 'brand-id': brandId } = this.siteConfig;
        // If the brand is in the list, we convert how the brand is configured in checkout services
        if (Object.keys(brandsEmailOpt).includes(brandId)) {
            brandId = brandsEmailOpt[brandId];
        }
        const accessToken = await auth.getAccessTokenSafely();
        const { ContactId, email: customDataEmail } = this.userProfile;
        const { email: profileEmail } = Helper.getSessionStorageObject(ContactId, 'userProfile');
        const currentEmail = profileEmail || customDataEmail;
        const message = 'Email address not found in the system.';
        const error = { message };
        let isSubscribed = false;
        this.wcEnv.rootUri = '/';
        const resourcePage = `profile/site/${brandId || '18F'}/user/${currentEmail}`;
        const response = await restClient.getFacade(this.wcEnv, resourcePage, accessToken, { rnd: Math.random() });
        delete response.headers;
        delete response.request;
        delete response.config;
        const { data: { retrieveEmailResponse: { retrieveEmailResponseResult: { retrieveEmailResult: { retrieveEmailMessage } } } } } = response;
        try {
            if (retrieveEmailMessage === 'Active') {
                isSubscribed = true;
                error.message = '';
            } else if (retrieveEmailMessage === 'Unsubscribed') {
                error.message = '';
            } else if (retrieveEmailMessage === 'NOTFOUND') {
                // NOTFOUND is Unsubscribed too
                error.message = '';
            } else if (retrieveEmailMessage === 'Held') {
                error.message = 'Email address is in Held status after multiple email bounces.';
            } else {
                error.message = retrieveEmailMessage;
            }
        } catch (err) {
            console.error(err);
            return {
                // eslint-disable-next-line object-curly-newline
                data: { response, isSubscribed, ContactId, error: { message: err.message }, retrieveEmailMessage },
            };
        }
        const { timestamp } = Helper.getSessionStorageObject(ContactId, 'emailPreference');
        return {
            // eslint-disable-next-line object-curly-newline
            data: { response, isSubscribed, email: currentEmail, ContactId, error, timestamp, retrieveEmailMessage },
        };
    };

    updateEmailPreference = async (data, requestAction) => {
        let { 'brand-id': brandId } = this.siteConfig;
        // If the brand is in the list, we convert how the brand is configured in checkout services
        if (Object.keys(brandsEmailOpt).includes(brandId)) {
            brandId = brandsEmailOpt[brandId];
        }
        const accessToken = await auth.getAccessTokenSafely();
        const { ContactId, email: customDataEmail } = this.userProfile;
        const { email: profileEmail } = Helper.getSessionStorageObject(ContactId, 'userProfile');
        const currentEmail = profileEmail || customDataEmail;
        const message = 'Email address not found in the system.';
        const error = { message };
        let isSubscribed = false;
        let action = '';
        if (requestAction && requestAction.length > 0) {
            action = requestAction;
        } else if (data && data.requestAction) {
            action = data.requestAction;
        }
        const payload = {
            email: currentEmail,
        };
        this.wcEnv.rootUri = '/';
        const resourcePage = `profile/site/${brandId || '18F'}/user/${currentEmail}/action/${action}`;
        const response = await restClient.postFacade(this.wcEnv, resourcePage, accessToken, payload);
        delete response.headers;
        delete response.request;
        delete response.config;
        try {
            const { data: { updateEmailResponse: { updateEmailResponseResult: { updateEmailResult: { updateEmailMessage } } } } } = response;
            if (/^OK--Created/.test(updateEmailMessage)) {
                isSubscribed = true;
                error.message = '';
            } else if (updateEmailMessage === 'Unsubscribed') {
                error.message = '';
            } else if (updateEmailMessage === 'Held') {
                error.message = 'Email address is in Held status after multiple email bounces.';
            } else {
                error.message = updateEmailMessage;
            }
        } catch (err) {
            console.error(err);
            return {
                // eslint-disable-next-line object-curly-newline
                data: { response, isSubscribed, ContactId, error: { message: err.message } },
            };
        }
        const timestamp = new Date().getTime();
        const object = {
            emailPreference: {
                isSubscribed,
                timestamp,
            },
        };
        Helper.setSessionStorageObject(ContactId, object);
        return {
            // eslint-disable-next-line object-curly-newline
            data: { response, isSubscribed, email: currentEmail, ContactId, error, timestamp },
        };
    };

    fetchSubscriptions = async ({ payload, subscriptions }) => {
        let subscriptionResMsg = 'Sorry! - we were unable to access your subscriptions. Please try again later.';
        if (subscriptions.length > 0) subscriptionResMsg = 'No more subscriptions available';
        const { customerId, page } = payload;
        const body = { customerId, page: page || 0, size: 10 };

        if (customerId === '') return { data: { subscriptions: [], error: true, page, subscriptionResMsg } };

        try {
            const accessToken = await auth.getAccessTokenSafely();
            const data = await fetchSubscriptionApi(this.wcEnv, accessToken, body);

            const { mySubscriptionResult } = data;
            subscriptions = [...mySubscriptionResult, ...subscriptions];
            if (mySubscriptionResult.length > 0) subscriptionResMsg = '';
            else subscriptionResMsg = 'No subscriptions available';
            if ((customerId && /\S+@\S+\.\S+/.test(customerId))) { // need this check for agent sub mgmt page
                return {
                    data: { subscriptions, error: false, page, subscriptionResMsg, fetchingSubscriptions: false },
                };
            }
            return {
                data: { subscriptions: [], error: false, page, subscriptionResMsg, fetchingSubscriptions: false },
            };
        } catch (err) {
            const message = `error in fetch or json parse: ${err}`;
            mbpLogger.logError({
                function: 'Services.fetchSubscriptions',
                appName: process.env.npm_package_name,
                module: 'mbp-account-ui',
                message,
            });
            return {
                data: { subscriptions: [], error: true, page, subscriptionResMsg, fetchingSubscriptions: false },

            };
        }
    };

    fetchSubscriptionByStatus = async ({ payload, subscriptions }) => {
        let subscriptionResMsg = 'No {subscription_status} subscriptions';
        if (subscriptions.length > 0) subscriptionResMsg = 'No more subscriptions available';
        const { customerId, page, status } = payload;
        const body = { customerId, page: page || 0, nextPage: 0, size: 5, status };

        if (customerId === '') return { data: { subscriptions: [], error: true, page, nextPage: false, subscriptionResMsg } };

        try {
            const accessToken = await auth.getAccessTokenSafely();
            const data = await fetchSubscriptionByStatusApi(this.wcEnv, accessToken, body);

            const { mySubscriptionResult, nextPage } = data;
            subscriptions.push(...mySubscriptionResult);
            if (mySubscriptionResult.length > 0) subscriptionResMsg = '';
            if ((customerId && /\S+@\S+\.\S+/.test(customerId))) { // need this check for agent sub mgmt page
                return {
                    data: { subscriptions, error: false, page, nextPage: nextPage > page, subscriptionResMsg, fetchingSubscriptions: false },
                };
            }
            return {
                data: { subscriptions: [], error: false, page, nextPage: nextPage > page, subscriptionResMsg, fetchingSubscriptions: false },
            };
        } catch (err) {
            subscriptionResMsg = 'Sorry! - we were unable to access your subscriptions. Please try again later.';
            const message = `error in fetch or json parse: ${err}`;
            mbpLogger.logError({
                function: 'Services.fetchSubscriptionByStatus',
                appName: process.env.npm_package_name,
                module: 'mbp-account-ui',
                message,
            });
            return {
                data: { subscriptions: [], error: true, page, nextPage: false, subscriptionResMsg, fetchingSubscriptions: false },
            };
        }
    };

    cancelSubscription = async ({ ruleId, agentId }) => {
        try {
            const accessToken = await auth.getAccessTokenSafely();
            await cancelSubscriptionApi(this.wcEnv, accessToken, { ruleId, cancelledFlag: true, agentId });

            return {
                data: { ruleId },
                error: null,
            };
        } catch (err) {
            const message = `error in fetch or json parse: ${err}`;
            mbpLogger.logError({
                function: 'Services.cancelSubscription',
                appName: process.env.npm_package_name,
                module: 'mbp-account-ui',
                ruleId,
                message,
            });
            return {
                data: null,
                error: { message },
            };
        }
    };

    cancellationSurvey = async ({ customerId, ruleId, productId, questionAndAnswerList }) => {
        let cancellationSurveyStatus;
        try {
            const accessToken = await auth.getAccessTokenSafely();
            cancellationSurveyStatus = await cancellationSurveyApi(this.wcEnv, accessToken, { customerId, ruleId, productId, questionAndAnswerList });
        } catch (err) {
            const message = `error in cancellation survey or json parse: ${err}`;
            mbpLogger.logError({
                function: 'Services.cancellationSurvey',
                appName: process.env.npm_package_name,
                module: 'mbp-account-ui',
                ruleId,
                message,
            });
        }
        return { data: { cancellationSurveyStatus } };
    };

    cancelAllUserSubscriptions = async ({ customerId }) => {
        const accessToken = await auth.getAccessTokenSafely();

        let subscriptionsCancelledStatus;
        let cancellationError;
        try {
            subscriptionsCancelledStatus = await massCancelUserSubscriptionsApi(this.wcEnv, accessToken, customerId);
        } catch (err) {
            const message = `error in request: ${err}`;
            mbpLogger.logError({
                function: 'Services.cancelAllUserSubscriptions',
                appName: process.env.npm_package_name,
                module: 'mbp-account-ui',
                customerId,
                message,
            });
            cancellationError = message;
        }
        return { data: { subscriptionsCancelledStatus, cancellationError } };
    };

    updateSubscription = async ({ ruleId, updateType }) => {
        try {
            const accessToken = await auth.getAccessTokenSafely();
            const data = await updateSubscriptionApi(this.wcEnv, accessToken, { ruleId, updateType });

            let nextShippingDate;
            if (data?.nextShippingDate) nextShippingDate = data.nextShippingDate;

            return {
                data: { ruleId, nextShippingDate },
                updateType,
                error: null,
            };
        } catch (err) {
            const message = `error in fetch or json parse: ${err}`;
            mbpLogger.logError({
                function: 'Services.updateSubscription',
                appName: process.env.npm_package_name,
                module: 'mbp-account-ui',
                ruleId,
                message,
            });
            return {
                data: null,
                updateType,
                error: { message },
            };
        }
    };

    updateFirstnameLastname = async (data) => {
        const { firstName, lastName } = data;
        const accessToken = await auth.getAccessTokenSafely();
        const { sub } = this.userProfile;
        const error = { message: GENERIC_ERROR };
        const resourcePage = `profile/auth0/${sub.replace('|', '%7C')}`;
        const payload = {
            givenName: firstName,
            familyName: lastName,
        };
        let profile;
        let response;
        this.wcEnv.rootUri = '/';
        await restClient.patchFacade(this.wcEnv, resourcePage, accessToken, payload)
            .then((result) => {
                response = result;
                delete response.headers;
                delete response.request;
                delete response.config;
            })
            .catch((err) => {
                ({ response } = err);
                try {
                    if (response.status === 401) {
                        error.message = 'Sorry, there was a problem. Please try logging in again.';
                    } else if (response.status === 429) {
                        error.message = 'Your account is temporarily disabled. Please check your email for instructions on how to update your account.';
                    }
                } catch (ex) {
                    response = err;
                }
            });
        try {
            if (response.data) {
                if (response.data.errorMessage) error.message = response.data.errorMessage;
                if (response.data.status === 'success') {
                    error.message = '';
                    // update firstName and lastName in localStorage and sessionStorage
                    const memberObj = JSON.parse(sessionStorage.getItem('persist:member'));
                    const lsMemberObj = JSON.parse(localStorage.getItem('userInfo'));
                    if (memberObj) {
                        ({ profile } = memberObj);
                        if (!isEmpty(profile) || profile) {
                            profile = JSON.parse(profile);
                            profile.firstName = firstName;
                            profile.lastName = lastName;
                            lsMemberObj.profile.firstName = firstName;
                            lsMemberObj.profile.lastName = lastName;
                            memberObj.profile = JSON.stringify(profile);
                            sessionStorage.setItem('persist:member', JSON.stringify(memberObj));
                            localStorage.setItem('userInfo', JSON.stringify(lsMemberObj));
                        }
                    }
                }
            }
            // eslint-disable-next-line no-empty
        } catch (ex) { }

        return {
            // eslint-disable-next-line object-curly-newline
            data: { response, error },
        };
    };

    updatePassword = async (data) => {
        const { currentPassword, newPassword } = data;
        const accessToken = await auth.getAccessTokenSafely();
        const { email, sub } = this.userProfile;
        const error = { message: GENERIC_ERROR };
        const resourcePage = `profile/auth0/${sub.replace('|', '%7C')}`;
        const payload = {
            logonId: email,
            oldPassword: currentPassword,
            password: newPassword,
        };
        let response;
        let statusCode;
        this.wcEnv.rootUri = '/';
        await restClient.patchFacade(this.wcEnv, resourcePage, accessToken, payload)
            .then((result) => {
                response = result;
                delete response.headers;
                delete response.request;
                delete response.config;
            })
            .catch((err) => {
                statusCode = err.response?.status;
                ({ response } = err);
                try {
                    if (response.status === 401) {
                        error.message = 'Sorry, your password was incorrect. Please double-check your password.';
                    } else if (response.status === 429) {
                        error.message = 'Your account is temporarily disabled. Please check your email for instructions on how to update your account.';
                    } else if (response.status === 503) {
                        error.message = 'Your account is temporarily disabled. Please check your email for instructions on how to update your account.';
                    }
                } catch (ex) {
                    statusCode = ex.response?.status;
                    response = err;
                }
            });
        try {
            if (response.data) {
                if (response.data.errorMessage) error.message = response.data.errorMessage;
                if (response.data.status === 'success') {
                    error.message = '';
                }
            }
            // eslint-disable-next-line no-empty
        } catch (ex) {
            statusCode = ex.response?.status;
        }

        return {
            // eslint-disable-next-line object-curly-newline
            data: { response, error, statusCode },
        };
    };

    changeNewEmailAddress = async (data) => {
        const { confirmnewemail } = data;
        const accessToken = await auth.getAccessTokenSafely();
        const isEmailChangeUsingOldEndpoint = this.featureFlags['is-email-change-using-old-profile-facade-endpoint'];
        const { ContactId, email, sub } = this.userProfile;
        const message = 'Email address not found in the system.';
        const error = { message };
        let newEmail = '';
        const resourcePage = isEmailChangeUsingOldEndpoint ? `profile/auth0/${sub.replace('|', '%7C')}` : 'retention/auth/profile/auth0/email';
        const payloadKey = isEmailChangeUsingOldEndpoint ? 'emailId' : 'newEmail';
        const payload = {
            [payloadKey]: confirmnewemail,
        };
        this.wcEnv.rootUri = '/';
        const response = await restClient.patchFacade(this.wcEnv, resourcePage, accessToken, payload)
            // .then(async (result) => {
            //     if (result.status === 200) {
            //     }
            //     return {};
            // })
            .catch((r) => {
                try {
                    error.message = r.response.data.message;
                } catch (e) {
                    error.message = r.message;
                }
                return {
                    data: { error },
                };
            });
        delete response.headers;
        delete response.request;
        delete response.config;
        if (response.data) {
            if (response.data.errorMessage) error.message = response.data.errorMessage;
            if (response.data.email) {
                newEmail = response.data.email;
                error.message = '';
            } else if (response.data.status === 'success') {
                newEmail = confirmnewemail;
                error.message = '';
            }
        }
        if (newEmail) {
            // Get previous email preference status
            const { data: { isSubscribed: prevSubscription } } = await this.fetchEmailPreference();
            if (prevSubscription) {
                // Unsubscribe previous email address
                await this.updateEmailPreference({}, 'DELETE');
            }
            const object = {
                userProfile: {
                    email: newEmail,
                    timestamp: new Date().getTime(),
                },
            };
            // Store new userProfile
            Helper.setSessionStorageObject(ContactId, object);
            if (prevSubscription) {
                // Subscribe new email address
                await this.updateEmailPreference({}, 'SUBSCRIBE');
            }
        }

        return {
            data: { response, email, newEmail, error },
        };
    };

    removeGiftListOrAddressBookRecipient = async (data) => {
        const { AddressBookEntryId, recipient: { occasionId, orderItemId }, addressType, currentPage, recipientsByPage } = data;
        const accessToken = await auth.getAccessTokenSafely();
        const { ContactId } = this.userProfile;
        const errorMessage = ['<p class="bold">', 'Oops...', '</p><p>', 'Something went wrong on the page!', '</p>'].join('');
        let response = {};
        if (addressType === 'ADDRESS_BOOK') {
            this.wcEnv.rootUri = '/';
            const resourcePage = `addressbook/contacts/${ContactId}/entries/${AddressBookEntryId}`;
            response = await restClient.deleteFacade(this.wcEnv, resourcePage, accessToken, {});
            delete response.headers;
            delete response.request;
            delete response.config;
            return {
                data: { response, ContactId },
                error: false,
            };
        }
        // if addressType === 'GIFT_LIST'
        this.wcEnv.rootUri = '/';
        const resourcePage = `giftlist/${occasionId}/recipient/${orderItemId}`;
        response = await restClient.deleteFacade(this.wcEnv, resourcePage, accessToken, {})
            .then(async (result) => {
                if (result.status === 200) {
                    // Remove gift list product from cart
                    await this.removeProductFromCart(data);

                    // Goto previous page if the last recipient has been deleted
                    const newPage = (recipientsByPage.length === 1 && currentPage > 1) ? (currentPage - 1) : currentPage;
                    // get recipients by page
                    const responseGiftList = await this.fetchGiftListRecipients({ setOccasionId: occasionId, newPage }, true);
                    return { giftList: { ...responseGiftList.data }, status: 200 };
                }
                return {};
            })
            .catch((r) => ({
                data: { error: { errorMessage: r.message } },
                error: false,
            }));
        return {
            data: { response, ContactId, error: { errorMessage } },
            error: false,
        };
    };

    encryptCreditCard = async (cardNumber, sourceID) => {
        const accessToken = await auth.getAccessTokenSafely();
        const resourcePage = 'cryptoservice/tokenize';
        const payload = {
            ccNumber: cardNumber,
            sourceID,
        };
        const error = {};
        this.wcEnv.rootUri = '/';
        return restClient.postFacade(this.wcEnv, resourcePage, accessToken, payload)
            .then((result) => {
                const response = result;
                delete response.headers;
                delete response.request;
                delete response.config;
                return {
                    data: { response, error },
                };
            })
            .catch((err) => ({ data: { response: {}, error: err } }));
    };

    saveCreditCard = async (data) => {
        const { wallet } = data;
        const accessToken = await auth.getAccessTokenSafely();
        const { ContactId } = this.userProfile;
        let statusCode;
        // const error = {};
        let response = {};
        return this.encryptCreditCard(wallet.cardNumber, wallet.sourceID)
            .then(async ({ data: { response: result, error } }) => {
                const errorObject = {};
                if (result.status === 200 && result.data.cyptoccNumber && result.data.cyptoccNumber !== 'FALSE') {
                    if (result.data.cyptoccNumber === 'ERROR') {
                        response = result;
                        errorObject.message = 'Please enter a valid card number.';
                        errorObject.type = 'field';
                        errorObject.name = 'cardnumber';
                    } else {
                        wallet.cardNumber = result.data.cyptoccNumber;
                        if (wallet.walletId) {
                            const resourcePage = `wallets/${ContactId}/cards/${wallet.walletId}`;
                            const payload = { wallet };
                            this.wcEnv.rootUri = '/';
                            await restClient.putFacade(this.wcEnv, resourcePage, accessToken, payload)
                                .then((res) => {
                                    response = res;
                                })
                                .catch((err) => {
                                    statusCode = err.response?.status;
                                    ({ response } = err);
                                    try {
                                        if (response.data.error.errorCode === '6006') {
                                            errorObject.message = 'Cannot have duplicate credit card number';
                                            errorObject.type = 'field';
                                            errorObject.name = 'cardnumber';
                                        }
                                    } catch (ex) {
                                        statusCode = ex.response?.status;
                                        response = err;
                                    }
                                });
                        } else {
                            const resourcePage = `wallets/${ContactId}/cards`;
                            const payload = { wallet };
                            this.wcEnv.rootUri = '/';
                            await restClient.postFacade(this.wcEnv, resourcePage, accessToken, payload)
                                .then((res) => {
                                    response = res;
                                })
                                .catch((err) => {
                                    statusCode = err.response?.status;
                                    ({ response } = err);
                                    try {
                                        if (response.data.error.errorCode === '4016') {
                                            errorObject.message = 'Cannot have duplicate credit card number';
                                            errorObject.type = 'field';
                                            errorObject.name = 'cardnumber';
                                        }
                                    } catch (ex) {
                                        statusCode = ex.response?.status;
                                        response = err;
                                    }
                                });
                        }
                    }
                } else {
                    response = result;
                    errorObject.message = error.message || 'Oops! Something went wrong.';
                    errorObject.type = 'popup';
                }
                delete response.headers;
                delete response.request;
                delete response.config;
                return {
                    data: { response, ContactId, error: errorObject, statusCode },
                };
            })
            .catch((err) => {
                statusCode = err.response?.status;
                return {
                    data: { response, ContactId, error: err, statusCode },
                };
            });
    };

    removeCreditCard = async (data) => {
        const { walletId } = data;
        const accessToken = await auth.getAccessTokenSafely();
        const { ContactId } = this.userProfile;
        const resourcePage = `wallets/${ContactId}/cards/${walletId}`;
        const payload = {};
        let statusCode;
        this.wcEnv.rootUri = '/';
        try {
            const response = await restClient.deleteFacade(this.wcEnv, resourcePage, accessToken, payload);
            delete response.headers;
            delete response.request;
            delete response.config;
            return {
                data: { response, ContactId },
                error: false,
            };
        } catch (err) {
            statusCode = err.response?.status;
            return {
                data: { response: {}, ContactId, statusCode },
                error: true,
            };
        }
    };

    setDefaultCreditCard = async (data) => {
        const { walletId } = data;
        const accessToken = await auth.getAccessTokenSafely();
        const { ContactId } = this.userProfile;
        let statusCode;
        const resourcePage = `wallets/${ContactId}/cards/${walletId}`;
        const payload = {
            wallet: {
                walletId,
                subsIndicator: 1,
            },
        };
        this.wcEnv.rootUri = '/';
        try {
            const response = await restClient.putFacade(this.wcEnv, resourcePage, accessToken, payload);
            delete response.headers;
            delete response.request;
            delete response.config;
            return {
                data: { response, ContactId },
                error: false,
            };
        } catch (err) {
            statusCode = err.response?.status;
            return {
                data: { response: {}, ContactId, statusCode },
                error: true,
            };
        }
    };

    // QAS ADDRESS SEARCH/FORMAT
    // This should be passed a params object that contains the searchString and the country
    searchAddress = async ({ params }) => {
        const isMyAccountQasOn = this.featureFlags?.['is-my-account-qas-on'] || false;

        let searchError = { Message: '' };
        let fallBackSearchError = {
            Message: '',
        };
        let response = {};
        let searchResults = [];

        if (isMyAccountQasOn) {
            try {
                response = await QasClient.qasGet({}, 'search', params);
                if (response.status === 200 && !isEmpty(response.data) && !isEmpty(response.data.results)) {
                    searchResults = response.data.results;
                }
            } catch (err) {
                fallBackSearchError = { Message: 'Error' };
                searchError = err?.response?.data || fallBackSearchError;
                mbpLogger.logError({
                    function: 'searchAddress',
                    message: 'Qas API Failed',
                    appName: process.env.npm_package_name,
                    module: 'Services',
                    jsError: err,
                });
            }
        }
        return {
            data: { searchResults, searchError },
        };
    };

    // This expects a passed address response object that was selected.
    // The object will have a "format" key and is required to parse.
    selectAddress = async ({ address }) => {
        const { format } = address;
        const formatParams = qs.parse(format.substring(format.indexOf('?') + 1));
        const response = await QasClient.qasGet({}, 'format', formatParams);
        const error = {};
        try {
            const { data: { Message } } = response;
            if (Message) error.errorMessage = Message;
        } catch (err) {
            console.error(err);
        }
        return {
            data: { response, error },
        };
    };

    updateRecipientAddress = async (data) => {
        const {
            currentPage,
            occasionId,
            orderItemId,
            templateName,
            AddressBookEntryId,
            recipientData,
            recipientData: { address: { IsBillingAddress }, address, addressEntry },
            recipient,
            billingAddress,
        } = data;
        const accessToken = await auth.getAccessTokenSafely();
        const { ContactId } = this.userProfile;
        const billingAddressEntryId = !isEmpty(billingAddress) ? billingAddress[0]?.AddressBookEntryId : '';

        let response = {
            data: {},
        };
        let statusCode;
        try {
            if (templateName !== 'GiftListRecipientForm') {
                if (AddressBookEntryId || (IsBillingAddress && billingAddressEntryId)) {
                    const entryId = (IsBillingAddress && billingAddressEntryId) ? billingAddressEntryId : AddressBookEntryId;
                    const resourcePage = `addressbook/contacts/${ContactId}/entries/${entryId}/address`;
                    this.wcEnv.rootUri = '/';
                    response = await restClient.putFacade(this.wcEnv, resourcePage, accessToken, recipientData);
                } else {
                    const resourcePage = `addressbook/contacts/${ContactId}/entryandaddress`;
                    this.wcEnv.rootUri = '/';
                    response = await restClient.postFacade(this.wcEnv, resourcePage, accessToken, recipientData);
                }
            } else {
                const resourcePage = orderItemId ? `giftlist/${occasionId}/recipient/${orderItemId}` : `giftlist/${occasionId}/recipient`;
                const profile = {
                    firstName: addressEntry.FirstName,
                    lastName: addressEntry.LastName,
                    locationType: address.AddressType,
                    businessTitle: '',
                    organizationName: addressEntry.EmployerName,
                    phoneNumber: addressEntry.PhoneNumber,
                    address1: address.AddressLineOne,
                    address2: address.AddressLineTwo,
                    city: address.City,
                    state: address.StateProvince,
                    zipCode: address.PostalCode,
                    country: address.Country,
                };
                if (occasionId) {
                    this.wcEnv.rootUri = '/';
                    const wcPromise = orderItemId ? await restClient.putFacade(this.wcEnv, resourcePage, accessToken, profile) : await restClient.postFacade(this.wcEnv, resourcePage, accessToken, profile);
                    if (wcPromise.status === 200 && !isEmpty(wcPromise.data) && wcPromise.data.addressId) {
                        response = await this.fetchGiftListRecipients({ setOccasionId: occasionId, newPage: currentPage }, true);
                        response.data.Result = {
                            occasionId,
                            orderItemId,
                            addressId: wcPromise.data.addressId, // return new addressId
                            templateName,
                            AddressBookEntryId,
                            ...recipientData,
                            ...response.data,
                        };
                        // Remove gift list product from cart
                        if (orderItemId && recipient.addToCart && !isEmpty(recipient.cartDetail)) {
                            await this.removeProductFromCart(data);
                        }
                    }
                }
            }
        } catch (err) {
            statusCode = err.response?.status;
        }
        const { Result } = response.data;
        return {
            data: { response: Result, ContactId, occasionId, templateName, error: {}, statusCode },
        };
    };

    getProductDetailBySKU = async (productId) => {
        const accessToken = await auth.getAccessTokenSafely();
        const { ContactId } = this.userProfile;
        // Get data from sessionStorage
        const accountStorage = sessionStorage.getItem(PERSIST_KEY);
        const partNumber = `${this.partnumPrefix}${productId}`;
        let storageJson = { [ContactId]: {} };
        if (accountStorage) {
            storageJson = JSON.parse(accountStorage);
            if (
                storageJson
                && storageJson[ContactId]
                && storageJson[ContactId].productsBySKU
                && storageJson[ContactId].productsBySKU[partNumber]) {
                const productsBySKU = storageJson[ContactId].productsBySKU[partNumber];
                return {
                    ...productsBySKU,
                };
            }
        }

        this.wcEnv.rootUri = '/';
        const resourcePage = 'aggregator/graphql';
        const payload = {
            query: getProductDetailByItemNumberQL([partNumber], this.domain, GRAPHQL_ENV),
        };
        const result = await restClient.postFacade(this.wcEnv, resourcePage, accessToken, payload);
        // Update sessionStorage
        if (result.status === 200 && !isEmpty(result.data.data) && !isEmpty(result.data.data.findSkuByPartNumbers)) {
            if (!storageJson[ContactId]) {
                storageJson = { [ContactId]: {} };
            }
            storageJson[ContactId] = {
                ...storageJson[ContactId],
                productsBySKU: { [partNumber]: { ...result.data, status: result.status } },
                timestamp: new Date().getTime(),
            };
            sessionStorage.setItem(PERSIST_KEY, JSON.stringify(storageJson));
            return { ...result.data, status: result.status };
        }
        return {};
    };

    getAddProductToCartError = (productCode, error) => {
        let errorMessage = '';
        // Set generic error message
        errorMessage = `<p class="bold">We're sorry!</p><p>The product '${productCode}' couldn't be added to add to cart.</p><p>Please try again later or contact customer service for assistance.</p>`;
        // Override error message if it's available in error response
        if (error.response?.data?.detailedError?.errorMessage === 'MAX_CART_SIZE_REACHED') {
            const customerCareNumber = this.siteConfig?.phoneNumber || '';
            errorMessage = `Your cart is full. Please complete this order to clear your cart before you start shopping again. For assistance, please contact customer service at <a href={tel:${customerCareNumber}} >${customerCareNumber}</a>`;
        } else if (error.response?.data?.detailedError?.errorMessage) {
            errorMessage = `<p class="bold">We're sorry!</p><p>${error.response?.data?.detailedError?.errorMessage}</p><p>Please try again later or contact customer service for assistance.</p>`;
        }
        return errorMessage;
    }

    addProductToCart = async (data) => {
        const accessToken = await auth.getAccessTokenSafely();
        const { productId, recipient } = data;
        const {
            AddressBookEntryId,
            orderItemId,
            firstname: firstName,
            lastname: lastName,
            products,
            deliveryDate,
            holidayCode,
            address: {
                street: address1,
                apt: address2,
                location: {
                    name: locationType,
                },
                city,
                company,
                state: {
                    id: stateID,
                },
                country: {
                    id: countryID,
                },
                zipcode: zipCode,
            },
            phoneNumber,
        } = recipient;
        let brandCode = this.siteConfig['product-brand-id'];
        let errorMessage = '<p class="bold">Oops...</p><p>Something went wrong on the page!';
        let response = {};
        let selectedProduct = {};
        let productCode = '';
        // const parentCatalogEntryId = '';
        let productsInCart = [];
        let productAddedToCart = '';
        let isCartUpdated = true;
        let itemsInCart = {};
        let seoproductDisplayURL = '';
        let personalization = {};
        let ageVerifyFlag = false;
        let deliveryIndicator = '';
        let availability = {};
        let parentProduct = {};
        let deliveryMethod = '';
        let deliveryIndicatorFlag = false;
        let priceRules = null;
        // let giftHistoryOccassionCode = '';
        const country = countryID ? getCountryCodeFromTwoToThreeDigit(countryID) : 'USA';
        const productIndex = products.findIndex((p) => p.id === productId);
        // const giftHistoryOrderItemId = orderItemId || '';
        // Get xOrderAttrValues from the first product for 4.2
        const [{
            xOrderAttrValues = {}, // set xOrderAttrValues' default value to {} preventing error from old data
        }] = products;
        // If product found in the recommendation list
        if (productIndex > -1) {
            selectedProduct = products[productIndex];
            ({
                partNumber: productCode,
                // parentCatalogEntryId,
                seoproductDisplayURL,
                customAttributes: {
                    personalization,
                    ageVerifyFlag,
                    deliveryIndicator,
                    parentProduct,
                    availability,
                },
                priceRules,
                // xOrderAttrValues,
            } = selectedProduct);
            const ageVerifiedFlag = ageVerifyFlag || 'false';
            deliveryIndicatorFlag = deliveryIndicator === 'MO' || deliveryIndicator === 'WO';
            const productType = (!isEmpty(parentProduct) && parentProduct?.productType) ? parentProduct?.productType : '';
            const personalizationTemplate = (!isEmpty(personalization) && personalization?.personalizationTemplate) ? personalization.personalizationTemplate : 'N';
            const personalizationType = (!isEmpty(personalization) && personalization?.personalizationType) ? personalization.personalizationType : null;
            // giftHistoryOccassionCode = xOrderAttrValues.giftHistoryOccassionCode;
            deliveryMethod = (!isEmpty(availability) && availability?.defaultDeliveryMethod) ? availability?.defaultDeliveryMethod : '';
            // Wine product:     ageVerifyFlag === 'true'  && productType === 'COLLECTION'
            // CYO Wine product: ageVerifyFlag === 'true'  && productType === 'CUSTOM_ASSORTMENT'
            // CYO product:      ageVerifyFlag === 'false' && productType === 'CUSTOM_ASSORTMENT'
            // PYO product:      ageVerifyFlag === 'false' && productType === 'REGULAR_PRODUCT' && personalizationTemplate === 'Y'
            // PYO Wine product: ageVerifyFlag === 'true'  && productType === 'REGULAR_PRODUCT' && personalizationTemplate === 'Y'
            // CLUB Wine product:ageVerifyFlag === 'true'  && productType === 'CLUB'
            // CLUB product:     ageVerifyFlag === 'false' && productType === 'CLUB'
            // Normal product:   ageVerifyFlag === 'false' && productType === 'REGULAR_PRODUCT'
            // Normal product:   ageVerifyFlag === 'false' && productType === null
            // Monthly or weekly products are showing up as REGULAR_PRODUCT deliveryIndicatorFlag = 'MO' or 'WO'
            const specialProduct = ageVerifiedFlag !== 'false'
                || parentProduct?.productType === 'CLUB'
                || parentProduct?.productType === 'COLLECTION'
                || parentProduct?.productType === 'CUSTOM_ASSORTMENT'
                || (
                    !['REGULAR_PRODUCT', 'REGULARPRODUCT'].includes(parentProduct?.productType)
                    && (
                        personalizationTemplate === 'Y'
                        || personalizationType === 'G'
                        || personalizationType === '0'
                    )
                );

            if (specialProduct) {
                // Redirect users to PDP with Shop the Site condition
                // after adding to cart, users can go back to the gift list page by clicking
                // on the `RETUR TO GIFT LIST` link on Mini Cart popup
                if (seoproductDisplayURL) {
                    response.seoproductDisplayURL = seoproductDisplayURL;
                    errorMessage = '';
                    // Store recipient in the Shop the Site session; and it'll be used when add to cart on PDP
                    const recipientWithGiftListURL = { ...recipient, giftListURL: '/account/gift-list', productType, deliveryIndicator };
                    this.storeShopTheSiteConfirmationInSessionStorage({ object: { [SHOP_THE_SITE_KEY]: recipientWithGiftListURL } });
                    return {
                        data: { response, isCartUpdated: false, productAddedToCart, error: { errorMessage } },
                    };
                }
            }
        } else {
            productCode = productId;
            // Get product detail by SKU Number to check whether user has entered the right product number
            const productDetails = await this.getProductDetailBySKU(productId);
            // If user entered the right product number
            if (
                productDetails.status === 200
                && !isEmpty(productDetails.data?.findSkuByPartNumbers[0]?.parentProduct)
            ) {
                // parentCatalogEntryId = '';
                // Destruct product's partNumber and product's URL
                const { productSkus, seo: { url } } = productDetails.data.findSkuByPartNumbers[0].parentProduct;
                const partNumber = productSkus?.[0]?.partNumber || '';
                if (partNumber) {
                    this.wcEnv.rootUri = '/';
                    const resourceURL = 'aggregator/graphql';
                    const payload = {
                        query: findSkusByFullPartNumbersQL([partNumber], this.domain, GRAPHQL_ENV),
                    };
                    // Verify if it's a special product
                    const customAttr = await restClient.postFacade(this.wcEnv, resourceURL, accessToken, payload);
                    try {
                        const customAttributes = customAttr.data?.data?.findSkusByFullPartNumbers;
                        ({ ageVerifyFlag, deliveryIndicator, parentProduct, personalization, availability } = customAttributes?.[0]);
                        deliveryIndicatorFlag = deliveryIndicator === 'MO' || deliveryIndicator === 'WO';
                        const ageVerifiedFlag = ageVerifyFlag || 'false';
                        const productType = (!isEmpty(parentProduct) && parentProduct?.productType) ? parentProduct?.productType : '';
                        const personalizationTemplate = (!isEmpty(personalization) && personalization?.personalizationTemplate) ? personalization.personalizationTemplate : 'N';
                        const personalizationType = (!isEmpty(personalization) && personalization?.personalizationType) ? personalization.personalizationType : null;
                        deliveryMethod = (!isEmpty(availability) && availability?.defaultDeliveryMethod) ? availability?.defaultDeliveryMethod : '';
                        // Wine product:     ageVerifyFlag === 'true'  && productType === 'COLLECTION'
                        // CYO Wine product: ageVerifyFlag === 'true'  && productType === 'CUSTOM_ASSORTMENT'
                        // CYO product:      ageVerifyFlag === 'false' && productType === 'CUSTOM_ASSORTMENT'
                        // PYO product:      ageVerifyFlag === 'false' && productType === 'REGULAR_PRODUCT' && personalizationTemplate === 'Y'
                        // PYO Wine product: ageVerifyFlag === 'true'  && productType === 'REGULAR_PRODUCT' && personalizationTemplate === 'Y'
                        // CLUB Wine product:ageVerifyFlag === 'true'  && productType === 'CLUB'
                        // CLUB product:     ageVerifyFlag === 'false' && productType === 'CLUB'
                        // Normal product:   ageVerifyFlag === 'false' && productType === 'REGULAR_PRODUCT'
                        // Normal product:   ageVerifyFlag === 'false' && productType === null
                        // Monthly or weekly products are showing up as REGULAR_PRODUCT deliveryIndicatorFlag = 'MO' or 'WO'
                        const specialProduct = ageVerifiedFlag !== 'false'
                            || parentProduct?.productType === 'CLUB'
                            || parentProduct?.productType === 'COLLECTION'
                            || parentProduct?.productType === 'CUSTOM_ASSORTMENT'
                            || (
                                !['REGULAR_PRODUCT', 'REGULARPRODUCT'].includes(parentProduct?.productType)
                                && (
                                    personalizationTemplate === 'Y'
                                    || personalizationType === 'G'
                                    || personalizationType === '0'
                                )
                            );
                        // If it's a special product and has a product's URL, set redirect to PDP with Shop the Site condition
                        if (specialProduct && url) {
                            response.seoproductDisplayURL = url;
                            errorMessage = '';
                            // Store recipient in the Shop the Site session; and it'll be used when add to cart on PDP
                            const recipientWithGiftListURL = { ...recipient, giftListURL: '/account/gift-list', productType, deliveryIndicator };
                            this.storeShopTheSiteConfirmationInSessionStorage({ object: { [SHOP_THE_SITE_KEY]: recipientWithGiftListURL } });
                            return {
                                data: { response, isCartUpdated: false, productAddedToCart, error: { errorMessage } },
                            };
                        }
                    } catch (ex) {
                        // Couldn't get custom attribute; we'll return value as product not found
                        errorMessage = `The product code ${productId} was not found.`;
                        return {
                            data: {
                                response: productDetails,
                                error: {
                                    errorMessage,
                                    type: 'field', // Set as error input field
                                    name: 'itemnumber', // input field name in the form
                                },
                            },
                        };
                    }
                }
            } else {
                errorMessage = `The product code ${productId} was not found.`;
                return {
                    data: {
                        response: productDetails,
                        error: {
                            errorMessage,
                            type: 'field', // Set as error input field
                            name: 'itemnumber', // input field name in the form
                        },
                    },
                };
            }
        }

        // Get brandCode based on SKU for cross site product
        if (/^[\d+]+-I-[\d+]+[a-zA-Z]?$/.test(productCode)) {
            const newBrandCode = productCode.replace(/^([\d+]+)-I-[\d+]+[a-zA-Z]?$/, '$1');
            if (newBrandCode.length === 4) {
                brandCode = newBrandCode;
            }
        } else if (/^[\d+]+-(P|I)-[\d+]+[a-zA-Z]?$/.test(selectedProduct.productPartNumber)) {
            // Get brandCode based on SKU for cross site product ^
            const newBrandCode = selectedProduct.productPartNumber.replace(/^([\d+]+)-(P|I)-[\d+]+[a-zA-Z]?$/, '$1');
            if (newBrandCode.length === 4) {
                brandCode = newBrandCode;
            }
        }

        // Get deliveryDate , holiday code for shipping accoridng to previous year sent item
        let delivery;
        if (deliveryDate && !deliveryIndicatorFlag && deliveryMethod) {
            delivery = {
                deliveryDate,
                zipCode,
                locationType,
                deliverySLA: deliveryMethod,
                holidayCode,
            };
        }

        const productPayload = {
            productCode,
            brandCode,
            // productId: parentCatalogEntryId, // remove new cartservice add to cart dont need this.
            quantity: 1,
            delivery,
            // Converting xOrderAttrValues object to attributes: [{ attributeName, attributeValue }, ...]
            attributes: Object.keys(xOrderAttrValues).map((key) => ({ attributeName: key, attributeValue: xOrderAttrValues[key] })),
        };

        // We'll send lineItemType attrinbute only when productType is CLUB or COLLECTION
        if (parentProduct?.productType === 'CLUB' || parentProduct?.productType === 'COLLECTION') {
            productPayload.lineItemType = parentProduct?.productType;
        }

        if (priceRules) {
            productPayload.priceRules = priceRules;
        }

        await cartServices.addToCart({
            env: this.wcEnv,
            jwtToken: accessToken,
            products: [{
                ...productPayload,
                recipientInfo: {
                    addressId: AddressBookEntryId,
                    entryId: AddressBookEntryId,

                    recipient: {
                        addressId: AddressBookEntryId,
                        firstName,
                        lastName,
                        address1,
                        address2,
                        address3: '',
                        addressTypeIndicator: locationType === 'Business' ? 'B' : 'R', // B=Business/R=Residential
                        city,
                        confirmEmail: '',
                        country,
                        email: '',
                        locationType, // "Residence,Business,Funeral home,Hospital,Apartment,School,Church,APO/FPO,P.O.Box",
                        mobilePhone: '',
                        organizationName: company,
                        state: stateID,
                        zipCode,
                        phoneNumber,
                    },
                },
                greeting: Helper.getGiftMessageObjectGiftList(recipient, productCode, availability?.productDeliveryType),
            }],
        })
            .then(async (result) => {
                response = result.data;
                response.status = result.status;

                response.productId = productCode;
                errorMessage = '';
                productAddedToCart = productCode;

                const orderIdFromCart = !isEmpty(response.cartId) ? response.cartId : '';
                // Always add recipient to cart after adding product to cart
                if (orderIdFromCart) {
                    ({ itemsInCartByAddressId: productsInCart, itemsInCart } = await this.getProductsInCart(orderIdFromCart));

                    if (!isEmpty(productsInCart)) {
                        const getItemDetailsFromCart = Helper.getItemDetailsFromCart(orderItemId, productsInCart);

                        if (!getItemDetailsFromCart.hasDeliveryDate) {
                            // Add Gift List's recipient to cart when the delivery date in cart is empty
                            // await this.addGiftListRecipientToCart(cartOrderItemId, recipient);
                            ({ itemsInCartByAddressId: productsInCart, itemsInCart } = await this.getProductsInCart(orderIdFromCart));
                        }

                        // Get product details for GA Tracking
                        response.productDetail = getItemDetailsFromCart.productDetail;
                    }
                }

                response.productsInCart = productsInCart;
                response.itemsInCart = itemsInCart;
            })
            .catch((error) => {
                productAddedToCart = '';
                isCartUpdated = false;
                errorMessage = this.getAddProductToCartError(productCode, error);
                // Try to redirect to PDP with Shop the Site condition when add to cart error
                // so that users won't lose track of adding gift to their recipient
                // after adding to cart, users can go back to the gift list page by clicking
                // on the `RETUR TO GIFT LIST` link on Mini Cart popup
                if (seoproductDisplayURL && isCartUpdated) {
                    response.seoproductDisplayURL = seoproductDisplayURL;
                    errorMessage = '';
                    // Store recipient in the Shop the Site session; and it'll be used when add to cart on PDP
                    const recipientWithGiftListURL = { ...recipient, giftListURL: '/account/gift-list', productType: parentProduct?.productType, deliveryIndicator };
                    this.storeShopTheSiteConfirmationInSessionStorage({ object: { [SHOP_THE_SITE_KEY]: recipientWithGiftListURL } });
                }
            });

        return {
            data: { response, isCartUpdated, productAddedToCart, error: { errorMessage } },
        };
    };

    addGiftListGreetingToCart = async (orderIdFromCart, cartOrderItemId, recipient, productCode = '1001', productType) => {
        const accessToken = await auth.getAccessTokenSafely();
        const { greetingMessage } = recipient;
        let giftMsgText = greetingMessage;
        let giftAuthor = '';
        // For floral brands 18F, FB, BRY and GPT product, gift message config has to be as HD
        let brandCode = productCode;
        if (['1001', '1010', '1029'].includes(brandCode) && productType === 'GPT') brandCode = '1019';
        const giftMessageConfig = Helper.getGiftMessageConfiguration(brandCode) || {};

        if (giftMessageConfig.giftMessageMaxLines > 1 && greetingMessage?.length > 0) {
            if (giftMsgText?.includes('|')) {
                const testMatch = greetingMessage.split('|');
                if (testMatch.length > 0 && testMatch[0] !== 'No Card Message') {
                    // Look for the last position have From: to assign it to the author
                    // otherwise we send the author empty
                    if (testMatch[testMatch.length - 1].includes('From:')) {
                        giftMsgText = testMatch.slice(0, -1)?.join('|');
                        giftAuthor = `${testMatch[testMatch.length - 1]?.replace('From:', '')}`;
                    } else {
                        const words = testMatch.join(' ').trim().split(' ');
                        let message = '';
                        const newContent = [];
                        words.forEach((word) => {
                            if (message.length + word.length + 1 <= giftMessageConfig.giftMessageMaxChars) {
                                message += `${word} `;
                            } else if (message.length + word.length <= giftMessageConfig.giftMessageMaxChars) {
                                message += word;
                            } else if (newContent.length < giftMessageConfig.giftMessageMaxLines) {
                                newContent.push(message);
                                message = `${word} `;
                            }
                        });
                        if (message && newContent.length < giftMessageConfig.giftMessageMaxLines) {
                            newContent.push(message);
                        }
                        const remainingLines = giftMessageConfig.giftMessageMaxLines - newContent.length > 0 ? '|'.repeat(giftMessageConfig.giftMessageMaxLines - newContent.length) : '';
                        giftMsgText = `${newContent.join('|')}${remainingLines}`;

                        giftAuthor = '';
                    }
                }
            } else {
                giftMsgText = giftMsgText.substring(0, giftMsgText.lastIndexOf('From:') + 1);
                giftAuthor = giftMsgText.substring(giftMsgText.lastIndexOf('From:') + 1, giftMsgText.length);
            }
        }

        this.wcEnv.rootUri = '/';
        const resourcePage = `checkout/cart/${orderIdFromCart}/item/${cartOrderItemId}?`;
        const payload = {
            greeting: {
                message: giftMsgText,
                signature: giftAuthor,
            },
        };
        const { data } = await restClient.putFacade(this.wcEnv, resourcePage, accessToken, payload).then((response) => response);
        return data;
    };

    addGiftListRecipientToCart = async (orderItemId, recipient) => {
        const accessToken = await auth.getAccessTokenSafely();
        const { address, firstname, lastname, phoneNumber, sourceAttributes: attributes } = recipient;
        const { source = 'wcs' } = attributes;
        this.wcEnv.rootUri = '/';
        const resourcePage = `checkout/cart/${orderItemId}/recipient`;
        const payload = {
            recipient: {
                address1: address?.street,
                address2: address?.apt,
                address3: '',
                city: address?.city,
                country: address?.country.id,
                dpvIndicator: 'Y',
                firstName: firstname || 'FirstName',
                isAddressVerified: 'V', // V - Verified
                isFormValid: false,
                isTelephoneMadatory: 'n',
                lastName: lastname,
                locationType: address?.location.name,
                nameOfLocation: '',
                organizationName: address?.company,
                phone: phoneNumber || '',
                state: address?.state?.id,
                zipCode: address?.zipcode,
            },
            updateStoredAddress: true,
        };
        if (source !== 'mongo') {
            payload.entryId = '';
        }
        const { data } = await restClient.putFacade(this.wcEnv, resourcePage, accessToken, payload).then((response) => response);
        return data;
    };

    updateOrderIdSession = (orderId) => {
        this.removeOrderIdSession();
        const persistCheckout = JSON.parse(sessionStorage.getItem('persist:checkout'));
        if (!isEmpty(persistCheckout) && /\{"orderId":""\}/.test(persistCheckout.order)) {
            persistCheckout.order = JSON.stringify({ orderId });
            sessionStorage.setItem('persist:checkout', JSON.stringify(persistCheckout));
        }
    };

    removeOrderIdSession = () => {
        const persistCheckout = JSON.parse(sessionStorage.getItem('persist:checkout'));
        if (!isEmpty(persistCheckout) && /\{"orderId":"[^"]+"\}/.test(persistCheckout.order)) {
            persistCheckout.order = JSON.stringify({ orderId: '' });
            sessionStorage.setItem('persist:checkout', JSON.stringify(persistCheckout));
        }
    };

    removeProductFromCart = async (data) => {
        const accessToken = await auth.getAccessTokenSafely();
        const { recipient: { cartDetail: cartData } } = data;
        let errorMessage = ['<p class="bold">', 'Oops...', '</p><p>', 'Something went wrong on the page!', '</p>'].join('');
        let response = {};
        let productsInCart = [];
        let itemsInCart = {};
        if (!isEmpty(cartData)) {
            const { orderItemId, orderId } = cartData;
            const payload = {
                env: this.wcEnv,
                jwtToken: accessToken,
                orderItemId,
                cartId: orderId,
            };

            await cartServices.removeOrderItem(payload)
                .then(async (result) => {
                    response = result.data || {};
                    response.status = result.status;
                    errorMessage = '';
                    if (response.status === 200 && response.result === 'SUCCESS') {
                        response.orderId = orderId;
                        ({ itemsInCartByAddressId: productsInCart, itemsInCart } = await this.getProductsInCart(orderId));
                    }
                    response.productsInCart = productsInCart;
                    response.itemsInCart = itemsInCart;
                })
                .catch((error) => {
                    console.error(error);
                    response.status = error.status;
                    return {
                        data: { response, isCartUpdated: false, error: { errorMessage } },
                    };
                });
        }
        return {
            data: { response, isCartUpdated: true, error: { errorMessage } },
        };
    };

    fetchModalContent = async (url) => {
        const accessToken = await auth.getAccessTokenSafely();
        this.wcEnv.rootUri = '/';
        const { data } = await restClient.getFacade(this.wcEnv, url, accessToken, {});
        return { data, error: false };
    };

    fetchCountryData = () => ({
        data: {
            locations: LocationTypes[this.brandName] || LocationTypes.default,
            states: Helper.refactorStateData(States),
            countries: Helper.refactorObjectData(Countries),
            APOFPO,
            relationships: Relationships,
            cardtypes: Helper.refactorObjectData(CreditCardTypes),
        },
        error: false,
    });

    updateSubscriptionRecipientForm = async ({ data }) => {
        const accessToken = await auth.getAccessTokenSafely();
        const profile = {
            ruleId: data.ruleId,
            streetAddress1: data.recipient.address1,
            streetAddress2: data.recipient.address2,
            streetAddress3: data.recipient.street3,
            attentionText: data.recipient.attentionText,
            cityName: data.recipient.city,
            state: data.recipient.state,
            zipCode: data.recipient.zipCode,
            countryCode: data.recipient.country,
            locationType: data.recipient.locationType,
            recipientFirstName: data.recipient.firstName,
            recipientLastName: data.recipient.lastName,
            organizationName: data.recipient.organizationName,
            phones: {
                type: data.recipient.phoneType,
                telephoneNumber: data.recipient.phone,
            },
        };
        await subscriptionRecipientUpdateApi(this.wcEnv, accessToken, profile, data.closeForm);
        return data;
    };

    fetchDeliveryDates = async ({ data }) => {
        const accessToken = await auth.getAccessTokenSafely();
        const payload = {
            ...data,
            orderType: '',
        };
        try {
            const response = await deliveryDatesAPI(accessToken, payload, null, null);
            delete response.headers;
            delete response.request;
            delete response.config;
            return { data: { deliveryDatesResponse: response?.data, responseStatus: response?.status } };
        } catch (error) {
            const message = `Error fetching Delivery Dates: Data: ${JSON.stringify(payload)}`;
            mbpLogger.logError({
                function: 'Services.fetchDeliveryDates',
                appName: process.env.npm_package_name,
                module: 'mbp-account-ui',
                message,
                error,
            });
            return { data: { fetchDeliveryDates: error } };
        }
    };

    fetchProductAvailability = async (data) => {
        const accessToken = await auth.getAccessTokenSafely();
        try {
            const response = await isProductAvailableOn(accessToken, data, null, null);
            delete response.headers;
            delete response.request;
            delete response.config;
            return { data: { productAvailable: response?.data?.productAvailable, productAvilableStatus: response?.status } };
        } catch (error) {
            const message = 'Error while fetching product availability';
            mbpLogger.logError({
                function: 'Services.fetchProductAvailability',
                appName: process.env.npm_package_name,
                module: 'mbp-account-ui',
                message,
                error,
            });
            return { data: { fetchProductAvailability: error } };
        }
    };

    submitModifiedOrder = async ({ data }) => {
        const accessToken = await auth.getAccessTokenSafely();
        data.channel = this.user;
        try {
            const response = await submitModifyOrderAPI(accessToken, data, null, null);
            delete response.headers;
            delete response.request;
            delete response.config;
            const modifiedOrderResponseStatus = response?.status;
            let modifiedOrder = {};
            if (modifiedOrderResponseStatus === 201) {
                modifiedOrder = response?.data;
            }
            return { data: { modifiedOrder, modifiedOrderResponseStatus }, error: false };
        } catch (err) {
            const message = 'Error while submitting Modified Order';
            mbpLogger.logError({
                function: 'submitModifiedOrder',
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
                message,
                error: err,
            });
            return { data: { modifiedOrderError: err } };
        }
    }

    fetchHDEditable = async ({ data }) => {
        const accessToken = await auth.getAccessTokenSafely();
        let isHDOrderModifiable;
        let isHDOrderModifiableError;
        const { orderId } = data;
        try {
            const response = await hdEditableCheck(this.wcEnv, accessToken, data);
            delete response.headers;
            delete response.request;
            delete response.config;
            if (response?.status === 200) {
                isHDOrderModifiable = response.data;
                isHDOrderModifiable.intOrderNumber = orderId;
            } else {
                isHDOrderModifiable = [];
            }
        } catch (err) {
            const message = 'Error fetching HD editable check';
            mbpLogger.logError({
                function: 'Services.fetchHDEditable',
                appName: process.env.npm_package_name,
                module: 'mbp-account-ui',
                message,
                error: err,
            });
            isHDOrderModifiableError = message;
        }
        return {
            data: { isHDOrderModifiable, isHDOrderModifiableError },
        };
    }

    fetchPassportAutoRenewalStatus = async ({ data }) => {
        const accessToken = await auth.getAccessTokenSafely();
        let autoRenewalStatus;
        let autoRenewalStatusError;

        try {
            autoRenewalStatus = await fetchPassportAutoRenewalStatusApi(this.wcEnv, accessToken, data);
        } catch (err) {
            const message = `error fetching Passport Auto renewal status: ${err}`;
            mbpLogger.logError({
                function: 'Services.fetchPassportAutoRenewalStatus',
                appName: process.env.npm_package_name,
                module: 'mbp-account-ui',
                message,
            });
            autoRenewalStatusError = message;
        }

        return {
            data: { autoRenewalStatus, autoRenewalStatusError },
        };
    }

    cancelPassportMembership = async ({ data }) => {
        const accessToken = await auth.getAccessTokenSafely();
        let cancelPassportMembershipStatus;
        let cancelPassportMembershipStatusError;
        try {
            cancelPassportMembershipStatus = await cancelPassportMembership(this.wcEnv, accessToken, data);
        } catch (err) {
            const message = `error cancelling Passport Membership: ${err}`;
            mbpLogger.logError({
                function: 'Services.cancelPassportMembership',
                appName: process.env.npm_package_name,
                module: 'mbp-account-ui',
                message,
            });
            cancelPassportMembershipStatusError = message;
        }
        return {
            data: { cancelPassportMembershipStatus, cancelPassportMembershipStatusError },
        };
    }

    swapSubscription = async ({ data }) => {
        const accessToken = await auth.getAccessTokenSafely();
        try {
            const res = await swapSubscriptionApi(this.wcEnv, accessToken, data);
            return {
                data: { response: res, message: 'subscription swapped successfully', isError: false, isSuccess: true },
            };
        } catch (err) {
            const message = `error swapping subscription: ${err}`;
            mbpLogger.logError({
                function: 'Services.swapSubscription',
                appName: process.env.npm_package_name,
                module: 'mbp-account-ui',
                message,
            });
            return {
                data: { message: 'unable to swap subscription', isError: true, isSuccess: false },
            };
        }
    }

    updatePassportAutoRenewalStatus = async ({ data }) => {
        const accessToken = await auth.getAccessTokenSafely();
        let isStatusUpdated;
        let statusUpdateError;

        try {
            isStatusUpdated = await updatePassportAutoRenewalStatusApi(this.wcEnv, accessToken, data);
        } catch (err) {
            const message = `error updating Passport Auto renewal status: ${err}`;
            mbpLogger.logError({
                function: 'Services.updatePassportAutoRenewalStatus',
                appName: process.env.npm_package_name,
                module: 'mbp-account-ui',
                message,
            });
            statusUpdateError = message;
        }

        return {
            data: { isStatusUpdated, statusUpdateError },
        };
    }

    setPassportAutoRenewalStatus = ({ data }) => ({ data });

    fetchSubscriptionGiftMessage = async ({ id }) => {
        const accessToken = await auth.getAccessTokenSafely();
        const { updatedMessage, error } = await fetchSubscriptionGiftMessageApi(accessToken, id);
        return {
            data: { subscriptionsGiftMessage: updatedMessage, clickedId: id },
            error,
        };
    };

    updateSubscriptionGiftMessage = async ({ data }) => {
        const accessToken = await auth.getAccessTokenSafely();

        this.wcEnv = { rootUri: '/' };
        const payload = {
            ruleId: data.ruleId,
            giftMessage: data.giftMessage,
        };
        const resourcePage = 'retention-subscription/passport/subscription/change-gift-message';
        const response = await restClient.putFacade(this.wcEnv, resourcePage, accessToken, payload);
        delete response.headers;
        delete response.request;
        delete response.config;
        const error = {};

        try {
            const updatedMessage = response?.data?.result;
            const responseStatus = response?.status;
            if (responseStatus === 200) {
                data.closeForm();
                error.message = '';
            } else {
                error.message = updatedMessage;
            }
        } catch (err) {
            mbpLogger.logError({
                function: 'updateSubscriptionGiftMessage',
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
                message: 'Update Subscription Gift message Failed',
            });

            error.message = err.message;
        }
        return { data: { subscriptionsGiftMessage: data.giftMessage }, error: false };
    }

    validateCaptcha = async ({ response, callBackURL }) => {
        const accessToken = await auth.getAccessTokenSafely();
        let validationStatus;
        let validationError;

        const reqBody = { response };

        try {
            const validationResponse = await validateCaptchaApi(this.wcEnv, accessToken, reqBody);
            if (validationResponse.result.includes('success')) validationStatus = 'SUCCESS';
            else validationStatus = 'FAILED';
        } catch (e) {
            validationError = 'FAILED';
        }

        return { data: { validationStatus, validationError, callBackURL } };
    }

    updateMarketplaceFormData = async ({ data }) => {
        const accessToken = await auth.getAccessTokenSafely();
        const env = mbpUtil.getEnv('APP_GRAPHQL_ENV');
        const endpoint = (env === 'production' || env === 'uat2')
            ? 'https://fast-api.800-flowers.net/r/api/retention/email/marketplace'
            : 'https://fast-api-staging.800-flowers.net/r/api/retention/email/marketplace';
        this.wcEnv.host = endpoint;
        const headers = {
            'content-type': 'application/json',
            accept: 'application/json',
            authorization: `Bearer ${accessToken}`,
        };
        const requestBody = {
            contactName: data.firstName,
            yourRole: data.yourRole,
            emailAddress: data.emailAddress,
            companyName: data.companyName,
            numberOfEmployees: data.numberOfEmployees,
            location: data.location,
            website: data.website,
            howDidYouHearAboutUs: data.howDidYouHearAboutUs,
            whatProductsAreYouBestKnownFor: data.whatProductsAreYouBestKnownFor,
            doYouCurrentlyDropShip: data.doYouCurrentlyDropShip,
            tellUsYourStoryAndALittleBitMoreAboutYourCompany: data.tellUsYourStoryAndALittleBitMoreAboutYourCompany,
            haveYouGottenAnyPress: data.haveYouGottenAnyPress,
            whyShouldYouSellWithUs: data.whyShouldYouSellWithUs,
            numberOfInstagramfollowers: data.numberOfInstagramfollowers,
            numberOfFacebookFans: data.numberOfFacebookFans,
            twitterHandle: data.twitterHandle,
            facebookHandle: data.facebookHandle,
            instagramHandle: data.instagramHandle,
        };

        let marketplaceStatus;
        let marketplaceStatusError;

        try {
            marketplaceStatus = await fetch(endpoint, {
                method: 'post',
                headers,
                mode: 'cors',
                cache: 'no-cache',
                body: JSON.stringify(requestBody),
            });
            marketplaceStatus = await marketplaceStatus.json();
        } catch (err) {
            const message = `error fetching market place form status: ${err}`;
            mbpLogger.logError({
                function: 'Services.updateMarketplaceFormData',
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
                message,
            });
            marketplaceStatusError = message;
        }

        return {
            data: { marketplaceStatus, marketplaceStatusError },
        };
    };

    clearAgentSubMgmtState = (data) => ({ ...data, data: { callBackURL: '' } });

    clearSubcriptionState = () => ({ data: { subscriptions: [], error: false, page: 0, subscriptionResMsg: 'No more subscriptions available' } });

    setASMCustomerEmail = ({ customerEmail, callBackURL }) => ({ data: { customerEmail, callBackURL } })

    updateSubscriptionFrequency = async (data) => {
        let errorMessage = '';
        let response;
        try {
            const accessToken = await auth.getAccessTokenSafely();
            response = await updateSubscriptionFrequencyApi(this.wcEnv, accessToken, data);
            if (response.result.includes('success')) response = 'SUCCESS';
            else response = 'FAILED';
        } catch (e) {
            mbpLogger.logError({
                function: 'Services.updateSubscriptionFrequency',
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
                message: `Error updating subscription frequency ${e}`,
            });
            errorMessage = 'FAILED';
        }
        return {
            data: { response, errorMessage },
        };
    }

    addAsmAgentNotes = async (data) => {
        let response;
        let errorMessage = false;
        try {
            const accessToken = await auth.getAccessTokenSafely();
            response = await addASMAgentNotesApi(this.wcEnv, accessToken, data);
            if (response?.result && response.result !== 'success') {
                errorMessage = true;
            }
        } catch (e) {
            mbpLogger.logError({
                function: 'Services.addAsmAgentNotes',
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
                message: `Error Adding Agent Notes for ASM ${e}`,
            });
            errorMessage = true;
        }

        return {
            data: { response, errorMessage, trackedRuleId: data?.ruleId },
        };
    }

    clearAgentAsmNotesState = () => ({})

    fetchAsmAgentNotes = async ({ data }) => {
        let response;
        let errorFetchingAgentNotes = false;
        try {
            const accessToken = await auth.getAccessTokenSafely();
            response = await fetchASMAgentNotesApi(this.wcEnv, accessToken, data);
            if (response?.length <= 0 || response?.result === 'subscription not found') {
                errorFetchingAgentNotes = true;
            }
        } catch (e) {
            mbpLogger.logError({
                function: 'Services.fetchAsmAgentNotes',
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
                message: `Error Fetching Agent Notes for ASM ${e}`,
            });
            errorFetchingAgentNotes = true;
        }

        return {
            data: { agentNotes: response, errorFetchingAgentNotes, trackedRuleId: data },
        };
    }

    setThumbsUpForDeliveryImage = async ({ payload }) => {
        const accessToken = await auth.getAccessTokenSafely();
        let isThumbsUpDeliveryImageAPISuccess;
        let isThumbsUpDeliveryImageError;
        try {
            const response = await thumbsUpForDeliveryImageAPI(accessToken, payload);
            delete response.headers;
            delete response.request;
            delete response.config;
            if (response?.status === 200) {
                isThumbsUpDeliveryImageAPISuccess = true;
            } else {
                isThumbsUpDeliveryImageAPISuccess = false;
            }
        } catch (err) {
            const message = 'Error fetching ThumbsUpDeliveryImage check';
            mbpLogger.logError({
                function: 'Services.fetchThumbsUpDeliveryImage',
                appName: process.env.npm_package_name,
                module: 'mbp-account-ui',
                message,
                error: err,
            });
            isThumbsUpDeliveryImageError = message;
        }
        return {
            data: { isThumbsUpDeliveryImageAPISuccess, isThumbsUpDeliveryImageError },
        };
    }
}
